// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import AutoPaginationTransform from "@graphprotocol/client-auto-pagination";
import StitchingMerger from "@graphql-mesh/merger-stitching";
import { printWithCache } from '@graphql-mesh/utils';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { ClaveBalancesTypes } from './sources/clave-balances/types';
import type { ClaveTypes } from './sources/clave/types';
import * as importedModule$0 from "./sources/clave-balances/introspectionSchema";
import * as importedModule$1 from "./sources/clave/introspectionSchema";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  Int8: any;
  Timestamp: any;
};

export type Query = {
  claveAccount?: Maybe<ClaveAccount>;
  claveAccounts: Array<ClaveAccount>;
  erc20?: Maybe<ERC20>;
  erc20S: Array<ERC20>;
  erc20Balance?: Maybe<ERC20Balance>;
  erc20Balances: Array<ERC20Balance>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  total?: Maybe<Total>;
  totals: Array<Total>;
  day?: Maybe<Day>;
  days: Array<Day>;
  week?: Maybe<Week>;
  weeks: Array<Week>;
  month?: Maybe<Month>;
  months: Array<Month>;
  earnPosition?: Maybe<EarnPosition>;
  earnPositions: Array<EarnPosition>;
  dailyEarnFlow?: Maybe<DailyEarnFlow>;
  dailyEarnFlows: Array<DailyEarnFlow>;
  weeklyEarnFlow?: Maybe<WeeklyEarnFlow>;
  weeklyEarnFlows: Array<WeeklyEarnFlow>;
  monthlyEarnFlow?: Maybe<MonthlyEarnFlow>;
  monthlyEarnFlows: Array<MonthlyEarnFlow>;
  dailySwappedTo?: Maybe<DailySwappedTo>;
  dailySwappedTos: Array<DailySwappedTo>;
  weeklySwappedTo?: Maybe<WeeklySwappedTo>;
  weeklySwappedTos: Array<WeeklySwappedTo>;
  monthlySwappedTo?: Maybe<MonthlySwappedTo>;
  monthlySwappedTos: Array<MonthlySwappedTo>;
  dayAccount?: Maybe<DayAccount>;
  dayAccounts: Array<DayAccount>;
  weekAccount?: Maybe<WeekAccount>;
  weekAccounts: Array<WeekAccount>;
  monthAccount?: Maybe<MonthAccount>;
  monthAccounts: Array<MonthAccount>;
  cashback?: Maybe<Cashback>;
  cashbacks: Array<Cashback>;
  referralFee?: Maybe<ReferralFee>;
  referralFees: Array<ReferralFee>;
  claveTransaction?: Maybe<ClaveTransaction>;
  claveTransactions: Array<ClaveTransaction>;
  inAppSwap?: Maybe<InAppSwap>;
  inAppSwaps: Array<InAppSwap>;
};


export type QueryclaveAccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryclaveAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ClaveAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ClaveAccount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryerc20Args = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryerc20SArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ERC20_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC20_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryerc20BalanceArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryerc20BalancesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ERC20Balance_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC20Balance_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_metaArgs = {
  block?: InputMaybe<Block_height>;
};


export type QuerytotalArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytotalsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Total_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Total_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydayArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydaysArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Day_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Day_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryweekArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryweeksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Week_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Week_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymonthArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymonthsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Month_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Month_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryearnPositionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryearnPositionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EarnPosition_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<EarnPosition_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydailyEarnFlowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydailyEarnFlowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyEarnFlow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DailyEarnFlow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryweeklyEarnFlowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryweeklyEarnFlowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WeeklyEarnFlow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WeeklyEarnFlow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymonthlyEarnFlowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymonthlyEarnFlowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MonthlyEarnFlow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MonthlyEarnFlow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydailySwappedToArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydailySwappedTosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailySwappedTo_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DailySwappedTo_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryweeklySwappedToArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryweeklySwappedTosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WeeklySwappedTo_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WeeklySwappedTo_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymonthlySwappedToArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymonthlySwappedTosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MonthlySwappedTo_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MonthlySwappedTo_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydayAccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerydayAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DayAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DayAccount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryweekAccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryweekAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WeekAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WeekAccount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymonthAccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymonthAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MonthAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MonthAccount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycashbackArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycashbacksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Cashback_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Cashback_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreferralFeeArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreferralFeesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFee_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ReferralFee_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryclaveTransactionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryclaveTransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ClaveTransaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ClaveTransaction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryinAppSwapArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryinAppSwapsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InAppSwap_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InAppSwap_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};

export type Subscription = {
  claveAccount?: Maybe<ClaveAccount>;
  claveAccounts: Array<ClaveAccount>;
  erc20?: Maybe<ERC20>;
  erc20S: Array<ERC20>;
  erc20Balance?: Maybe<ERC20Balance>;
  erc20Balances: Array<ERC20Balance>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  total?: Maybe<Total>;
  totals: Array<Total>;
  day?: Maybe<Day>;
  days: Array<Day>;
  week?: Maybe<Week>;
  weeks: Array<Week>;
  month?: Maybe<Month>;
  months: Array<Month>;
  earnPosition?: Maybe<EarnPosition>;
  earnPositions: Array<EarnPosition>;
  dailyEarnFlow?: Maybe<DailyEarnFlow>;
  dailyEarnFlows: Array<DailyEarnFlow>;
  weeklyEarnFlow?: Maybe<WeeklyEarnFlow>;
  weeklyEarnFlows: Array<WeeklyEarnFlow>;
  monthlyEarnFlow?: Maybe<MonthlyEarnFlow>;
  monthlyEarnFlows: Array<MonthlyEarnFlow>;
  dailySwappedTo?: Maybe<DailySwappedTo>;
  dailySwappedTos: Array<DailySwappedTo>;
  weeklySwappedTo?: Maybe<WeeklySwappedTo>;
  weeklySwappedTos: Array<WeeklySwappedTo>;
  monthlySwappedTo?: Maybe<MonthlySwappedTo>;
  monthlySwappedTos: Array<MonthlySwappedTo>;
  dayAccount?: Maybe<DayAccount>;
  dayAccounts: Array<DayAccount>;
  weekAccount?: Maybe<WeekAccount>;
  weekAccounts: Array<WeekAccount>;
  monthAccount?: Maybe<MonthAccount>;
  monthAccounts: Array<MonthAccount>;
  cashback?: Maybe<Cashback>;
  cashbacks: Array<Cashback>;
  referralFee?: Maybe<ReferralFee>;
  referralFees: Array<ReferralFee>;
  claveTransaction?: Maybe<ClaveTransaction>;
  claveTransactions: Array<ClaveTransaction>;
  inAppSwap?: Maybe<InAppSwap>;
  inAppSwaps: Array<InAppSwap>;
};


export type SubscriptionclaveAccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionclaveAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ClaveAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ClaveAccount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionerc20Args = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionerc20SArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ERC20_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC20_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionerc20BalanceArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionerc20BalancesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ERC20Balance_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC20Balance_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>;
};


export type SubscriptiontotalArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontotalsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Total_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Total_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondayArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondaysArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Day_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Day_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionweekArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionweeksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Week_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Week_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmonthArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmonthsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Month_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Month_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionearnPositionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionearnPositionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EarnPosition_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<EarnPosition_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondailyEarnFlowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondailyEarnFlowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyEarnFlow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DailyEarnFlow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionweeklyEarnFlowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionweeklyEarnFlowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WeeklyEarnFlow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WeeklyEarnFlow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmonthlyEarnFlowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmonthlyEarnFlowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MonthlyEarnFlow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MonthlyEarnFlow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondailySwappedToArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondailySwappedTosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailySwappedTo_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DailySwappedTo_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionweeklySwappedToArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionweeklySwappedTosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WeeklySwappedTo_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WeeklySwappedTo_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmonthlySwappedToArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmonthlySwappedTosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MonthlySwappedTo_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MonthlySwappedTo_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondayAccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiondayAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DayAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DayAccount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionweekAccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionweekAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WeekAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WeekAccount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmonthAccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmonthAccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MonthAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MonthAccount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncashbackArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncashbacksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Cashback_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Cashback_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreferralFeeArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreferralFeesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFee_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ReferralFee_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionclaveTransactionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionclaveTransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ClaveTransaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ClaveTransaction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioninAppSwapArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioninAppSwapsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InAppSwap_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InAppSwap_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};

export type Aggregation_interval =
  | 'hour'
  | 'day';

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type ClaveAccount = {
  /** account address */
  id: Scalars['Bytes'];
  erc20balances: Array<ERC20Balance>;
  creationDate: Scalars['BigInt'];
  deployDate?: Maybe<Scalars['BigInt']>;
  recoveryCount: Scalars['Int'];
  hasRecovery: Scalars['Boolean'];
  isRecovering: Scalars['Boolean'];
  txCount: Scalars['Int'];
  /** account implementation address */
  implementation?: Maybe<Scalars['Bytes']>;
  earnPositions: Array<EarnPosition>;
  transactions: Array<ClaveTransaction>;
  inAppSwaps: Array<InAppSwap>;
  activeDays: Array<DayAccount>;
  activeWeeks: Array<WeekAccount>;
  activeMonths: Array<MonthAccount>;
  cashbacks: Array<Cashback>;
  referralFees: Array<ReferralFee>;
};


export type ClaveAccounterc20balancesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ERC20Balance_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC20Balance_filter>;
};


export type ClaveAccountearnPositionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EarnPosition_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<EarnPosition_filter>;
};


export type ClaveAccounttransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ClaveTransaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ClaveTransaction_filter>;
};


export type ClaveAccountinAppSwapsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<InAppSwap_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<InAppSwap_filter>;
};


export type ClaveAccountactiveDaysArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DayAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DayAccount_filter>;
};


export type ClaveAccountactiveWeeksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WeekAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WeekAccount_filter>;
};


export type ClaveAccountactiveMonthsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MonthAccount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MonthAccount_filter>;
};


export type ClaveAccountcashbacksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Cashback_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Cashback_filter>;
};


export type ClaveAccountreferralFeesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFee_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ReferralFee_filter>;
};

export type ClaveAccount_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  erc20balances_?: InputMaybe<ERC20Balance_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ClaveAccount_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ClaveAccount_filter>>>;
  creationDate?: InputMaybe<Scalars['BigInt']>;
  creationDate_not?: InputMaybe<Scalars['BigInt']>;
  creationDate_gt?: InputMaybe<Scalars['BigInt']>;
  creationDate_lt?: InputMaybe<Scalars['BigInt']>;
  creationDate_gte?: InputMaybe<Scalars['BigInt']>;
  creationDate_lte?: InputMaybe<Scalars['BigInt']>;
  creationDate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  creationDate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  deployDate?: InputMaybe<Scalars['BigInt']>;
  deployDate_not?: InputMaybe<Scalars['BigInt']>;
  deployDate_gt?: InputMaybe<Scalars['BigInt']>;
  deployDate_lt?: InputMaybe<Scalars['BigInt']>;
  deployDate_gte?: InputMaybe<Scalars['BigInt']>;
  deployDate_lte?: InputMaybe<Scalars['BigInt']>;
  deployDate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  deployDate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  recoveryCount?: InputMaybe<Scalars['Int']>;
  recoveryCount_not?: InputMaybe<Scalars['Int']>;
  recoveryCount_gt?: InputMaybe<Scalars['Int']>;
  recoveryCount_lt?: InputMaybe<Scalars['Int']>;
  recoveryCount_gte?: InputMaybe<Scalars['Int']>;
  recoveryCount_lte?: InputMaybe<Scalars['Int']>;
  recoveryCount_in?: InputMaybe<Array<Scalars['Int']>>;
  recoveryCount_not_in?: InputMaybe<Array<Scalars['Int']>>;
  hasRecovery?: InputMaybe<Scalars['Boolean']>;
  hasRecovery_not?: InputMaybe<Scalars['Boolean']>;
  hasRecovery_in?: InputMaybe<Array<Scalars['Boolean']>>;
  hasRecovery_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isRecovering?: InputMaybe<Scalars['Boolean']>;
  isRecovering_not?: InputMaybe<Scalars['Boolean']>;
  isRecovering_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isRecovering_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  txCount?: InputMaybe<Scalars['Int']>;
  txCount_not?: InputMaybe<Scalars['Int']>;
  txCount_gt?: InputMaybe<Scalars['Int']>;
  txCount_lt?: InputMaybe<Scalars['Int']>;
  txCount_gte?: InputMaybe<Scalars['Int']>;
  txCount_lte?: InputMaybe<Scalars['Int']>;
  txCount_in?: InputMaybe<Array<Scalars['Int']>>;
  txCount_not_in?: InputMaybe<Array<Scalars['Int']>>;
  implementation?: InputMaybe<Scalars['Bytes']>;
  implementation_not?: InputMaybe<Scalars['Bytes']>;
  implementation_gt?: InputMaybe<Scalars['Bytes']>;
  implementation_lt?: InputMaybe<Scalars['Bytes']>;
  implementation_gte?: InputMaybe<Scalars['Bytes']>;
  implementation_lte?: InputMaybe<Scalars['Bytes']>;
  implementation_in?: InputMaybe<Array<Scalars['Bytes']>>;
  implementation_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  implementation_contains?: InputMaybe<Scalars['Bytes']>;
  implementation_not_contains?: InputMaybe<Scalars['Bytes']>;
  earnPositions_?: InputMaybe<EarnPosition_filter>;
  transactions_?: InputMaybe<ClaveTransaction_filter>;
  inAppSwaps_?: InputMaybe<InAppSwap_filter>;
  activeDays_?: InputMaybe<DayAccount_filter>;
  activeWeeks_?: InputMaybe<WeekAccount_filter>;
  activeMonths_?: InputMaybe<MonthAccount_filter>;
  cashbacks_?: InputMaybe<Cashback_filter>;
  referralFees_?: InputMaybe<ReferralFee_filter>;
};

export type ClaveAccount_orderBy =
  | 'id'
  | 'erc20balances'
  | 'creationDate'
  | 'deployDate'
  | 'recoveryCount'
  | 'hasRecovery'
  | 'isRecovering'
  | 'txCount'
  | 'implementation'
  | 'earnPositions'
  | 'transactions'
  | 'inAppSwaps'
  | 'activeDays'
  | 'activeWeeks'
  | 'activeMonths'
  | 'cashbacks'
  | 'referralFees';

export type ERC20 = {
  /** token address */
  id: Scalars['Bytes'];
  totalAmount: Scalars['BigInt'];
};

export type ERC20Balance = {
  /** account.id.concat(ERC20.id) */
  id: Scalars['Bytes'];
  account: ClaveAccount;
  token: ERC20;
  amount: Scalars['BigInt'];
};

export type ERC20Balance_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  account?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<ClaveAccount_filter>;
  token?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<ERC20_filter>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ERC20Balance_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ERC20Balance_filter>>>;
};

export type ERC20Balance_orderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'token'
  | 'token__id'
  | 'token__totalAmount'
  | 'amount';

export type ERC20_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  totalAmount?: InputMaybe<Scalars['BigInt']>;
  totalAmount_not?: InputMaybe<Scalars['BigInt']>;
  totalAmount_gt?: InputMaybe<Scalars['BigInt']>;
  totalAmount_lt?: InputMaybe<Scalars['BigInt']>;
  totalAmount_gte?: InputMaybe<Scalars['BigInt']>;
  totalAmount_lte?: InputMaybe<Scalars['BigInt']>;
  totalAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ERC20_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ERC20_filter>>>;
};

export type ERC20_orderBy =
  | 'id'
  | 'totalAmount';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type Cashback = {
  /** account.id.concat(erc20).concat('0xcb') */
  id: Scalars['Bytes'];
  account: ClaveAccount;
  erc20: Scalars['Bytes'];
  amount: Scalars['BigInt'];
};

export type Cashback_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  account?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<ClaveAccount_filter>;
  erc20?: InputMaybe<Scalars['Bytes']>;
  erc20_not?: InputMaybe<Scalars['Bytes']>;
  erc20_gt?: InputMaybe<Scalars['Bytes']>;
  erc20_lt?: InputMaybe<Scalars['Bytes']>;
  erc20_gte?: InputMaybe<Scalars['Bytes']>;
  erc20_lte?: InputMaybe<Scalars['Bytes']>;
  erc20_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_contains?: InputMaybe<Scalars['Bytes']>;
  erc20_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Cashback_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Cashback_filter>>>;
};

export type Cashback_orderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'account__creationDate'
  | 'account__deployDate'
  | 'account__recoveryCount'
  | 'account__hasRecovery'
  | 'account__isRecovering'
  | 'account__txCount'
  | 'account__implementation'
  | 'erc20'
  | 'amount';

export type ClaveTransaction = {
  /** transaction hash */
  id: Scalars['Bytes'];
  /** tx.from */
  sender: ClaveAccount;
  /** tx.to */
  to?: Maybe<Scalars['Bytes']>;
  /** tx.value */
  value: Scalars['BigInt'];
  /** tx.gasPrice * tx.gasUsed */
  gasCost: Scalars['BigInt'];
  /** type of paymaster used */
  paymaster: Paymaster;
  /** block.timestamp */
  date: Scalars['BigInt'];
};

export type ClaveTransaction_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  sender?: InputMaybe<Scalars['String']>;
  sender_not?: InputMaybe<Scalars['String']>;
  sender_gt?: InputMaybe<Scalars['String']>;
  sender_lt?: InputMaybe<Scalars['String']>;
  sender_gte?: InputMaybe<Scalars['String']>;
  sender_lte?: InputMaybe<Scalars['String']>;
  sender_in?: InputMaybe<Array<Scalars['String']>>;
  sender_not_in?: InputMaybe<Array<Scalars['String']>>;
  sender_contains?: InputMaybe<Scalars['String']>;
  sender_contains_nocase?: InputMaybe<Scalars['String']>;
  sender_not_contains?: InputMaybe<Scalars['String']>;
  sender_not_contains_nocase?: InputMaybe<Scalars['String']>;
  sender_starts_with?: InputMaybe<Scalars['String']>;
  sender_starts_with_nocase?: InputMaybe<Scalars['String']>;
  sender_not_starts_with?: InputMaybe<Scalars['String']>;
  sender_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  sender_ends_with?: InputMaybe<Scalars['String']>;
  sender_ends_with_nocase?: InputMaybe<Scalars['String']>;
  sender_not_ends_with?: InputMaybe<Scalars['String']>;
  sender_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  sender_?: InputMaybe<ClaveAccount_filter>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  value?: InputMaybe<Scalars['BigInt']>;
  value_not?: InputMaybe<Scalars['BigInt']>;
  value_gt?: InputMaybe<Scalars['BigInt']>;
  value_lt?: InputMaybe<Scalars['BigInt']>;
  value_gte?: InputMaybe<Scalars['BigInt']>;
  value_lte?: InputMaybe<Scalars['BigInt']>;
  value_in?: InputMaybe<Array<Scalars['BigInt']>>;
  value_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  gasCost?: InputMaybe<Scalars['BigInt']>;
  gasCost_not?: InputMaybe<Scalars['BigInt']>;
  gasCost_gt?: InputMaybe<Scalars['BigInt']>;
  gasCost_lt?: InputMaybe<Scalars['BigInt']>;
  gasCost_gte?: InputMaybe<Scalars['BigInt']>;
  gasCost_lte?: InputMaybe<Scalars['BigInt']>;
  gasCost_in?: InputMaybe<Array<Scalars['BigInt']>>;
  gasCost_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  paymaster?: InputMaybe<Paymaster>;
  paymaster_not?: InputMaybe<Paymaster>;
  paymaster_in?: InputMaybe<Array<Paymaster>>;
  paymaster_not_in?: InputMaybe<Array<Paymaster>>;
  date?: InputMaybe<Scalars['BigInt']>;
  date_not?: InputMaybe<Scalars['BigInt']>;
  date_gt?: InputMaybe<Scalars['BigInt']>;
  date_lt?: InputMaybe<Scalars['BigInt']>;
  date_gte?: InputMaybe<Scalars['BigInt']>;
  date_lte?: InputMaybe<Scalars['BigInt']>;
  date_in?: InputMaybe<Array<Scalars['BigInt']>>;
  date_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ClaveTransaction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ClaveTransaction_filter>>>;
};

export type ClaveTransaction_orderBy =
  | 'id'
  | 'sender'
  | 'sender__id'
  | 'sender__creationDate'
  | 'sender__deployDate'
  | 'sender__recoveryCount'
  | 'sender__hasRecovery'
  | 'sender__isRecovering'
  | 'sender__txCount'
  | 'sender__implementation'
  | 'to'
  | 'value'
  | 'gasCost'
  | 'paymaster'
  | 'date';

export type DailyEarnFlow = {
  /** day.id.concat(erc20).concat(protocol) */
  id: Scalars['Bytes'];
  day: Day;
  erc20: Scalars['Bytes'];
  protocol: EarnProtocol;
  amountIn: Scalars['BigInt'];
  amountOut: Scalars['BigInt'];
  claimedGain: Scalars['BigInt'];
};

export type DailyEarnFlow_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  day?: InputMaybe<Scalars['String']>;
  day_not?: InputMaybe<Scalars['String']>;
  day_gt?: InputMaybe<Scalars['String']>;
  day_lt?: InputMaybe<Scalars['String']>;
  day_gte?: InputMaybe<Scalars['String']>;
  day_lte?: InputMaybe<Scalars['String']>;
  day_in?: InputMaybe<Array<Scalars['String']>>;
  day_not_in?: InputMaybe<Array<Scalars['String']>>;
  day_contains?: InputMaybe<Scalars['String']>;
  day_contains_nocase?: InputMaybe<Scalars['String']>;
  day_not_contains?: InputMaybe<Scalars['String']>;
  day_not_contains_nocase?: InputMaybe<Scalars['String']>;
  day_starts_with?: InputMaybe<Scalars['String']>;
  day_starts_with_nocase?: InputMaybe<Scalars['String']>;
  day_not_starts_with?: InputMaybe<Scalars['String']>;
  day_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  day_ends_with?: InputMaybe<Scalars['String']>;
  day_ends_with_nocase?: InputMaybe<Scalars['String']>;
  day_not_ends_with?: InputMaybe<Scalars['String']>;
  day_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  day_?: InputMaybe<Day_filter>;
  erc20?: InputMaybe<Scalars['Bytes']>;
  erc20_not?: InputMaybe<Scalars['Bytes']>;
  erc20_gt?: InputMaybe<Scalars['Bytes']>;
  erc20_lt?: InputMaybe<Scalars['Bytes']>;
  erc20_gte?: InputMaybe<Scalars['Bytes']>;
  erc20_lte?: InputMaybe<Scalars['Bytes']>;
  erc20_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_contains?: InputMaybe<Scalars['Bytes']>;
  erc20_not_contains?: InputMaybe<Scalars['Bytes']>;
  protocol?: InputMaybe<EarnProtocol>;
  protocol_not?: InputMaybe<EarnProtocol>;
  protocol_in?: InputMaybe<Array<EarnProtocol>>;
  protocol_not_in?: InputMaybe<Array<EarnProtocol>>;
  amountIn?: InputMaybe<Scalars['BigInt']>;
  amountIn_not?: InputMaybe<Scalars['BigInt']>;
  amountIn_gt?: InputMaybe<Scalars['BigInt']>;
  amountIn_lt?: InputMaybe<Scalars['BigInt']>;
  amountIn_gte?: InputMaybe<Scalars['BigInt']>;
  amountIn_lte?: InputMaybe<Scalars['BigInt']>;
  amountIn_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountIn_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOut?: InputMaybe<Scalars['BigInt']>;
  amountOut_not?: InputMaybe<Scalars['BigInt']>;
  amountOut_gt?: InputMaybe<Scalars['BigInt']>;
  amountOut_lt?: InputMaybe<Scalars['BigInt']>;
  amountOut_gte?: InputMaybe<Scalars['BigInt']>;
  amountOut_lte?: InputMaybe<Scalars['BigInt']>;
  amountOut_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOut_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  claimedGain?: InputMaybe<Scalars['BigInt']>;
  claimedGain_not?: InputMaybe<Scalars['BigInt']>;
  claimedGain_gt?: InputMaybe<Scalars['BigInt']>;
  claimedGain_lt?: InputMaybe<Scalars['BigInt']>;
  claimedGain_gte?: InputMaybe<Scalars['BigInt']>;
  claimedGain_lte?: InputMaybe<Scalars['BigInt']>;
  claimedGain_in?: InputMaybe<Array<Scalars['BigInt']>>;
  claimedGain_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DailyEarnFlow_filter>>>;
  or?: InputMaybe<Array<InputMaybe<DailyEarnFlow_filter>>>;
};

export type DailyEarnFlow_orderBy =
  | 'id'
  | 'day'
  | 'day__id'
  | 'day__createdAccounts'
  | 'day__deployedAccounts'
  | 'day__activeAccounts'
  | 'day__transactions'
  | 'day__gasSponsored'
  | 'erc20'
  | 'protocol'
  | 'amountIn'
  | 'amountOut'
  | 'claimedGain';

export type DailySwappedTo = {
  /** day.id.concat(erc20) */
  id: Scalars['Bytes'];
  day: Day;
  erc20: Scalars['Bytes'];
  amount: Scalars['BigInt'];
};

export type DailySwappedTo_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  day?: InputMaybe<Scalars['String']>;
  day_not?: InputMaybe<Scalars['String']>;
  day_gt?: InputMaybe<Scalars['String']>;
  day_lt?: InputMaybe<Scalars['String']>;
  day_gte?: InputMaybe<Scalars['String']>;
  day_lte?: InputMaybe<Scalars['String']>;
  day_in?: InputMaybe<Array<Scalars['String']>>;
  day_not_in?: InputMaybe<Array<Scalars['String']>>;
  day_contains?: InputMaybe<Scalars['String']>;
  day_contains_nocase?: InputMaybe<Scalars['String']>;
  day_not_contains?: InputMaybe<Scalars['String']>;
  day_not_contains_nocase?: InputMaybe<Scalars['String']>;
  day_starts_with?: InputMaybe<Scalars['String']>;
  day_starts_with_nocase?: InputMaybe<Scalars['String']>;
  day_not_starts_with?: InputMaybe<Scalars['String']>;
  day_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  day_ends_with?: InputMaybe<Scalars['String']>;
  day_ends_with_nocase?: InputMaybe<Scalars['String']>;
  day_not_ends_with?: InputMaybe<Scalars['String']>;
  day_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  day_?: InputMaybe<Day_filter>;
  erc20?: InputMaybe<Scalars['Bytes']>;
  erc20_not?: InputMaybe<Scalars['Bytes']>;
  erc20_gt?: InputMaybe<Scalars['Bytes']>;
  erc20_lt?: InputMaybe<Scalars['Bytes']>;
  erc20_gte?: InputMaybe<Scalars['Bytes']>;
  erc20_lte?: InputMaybe<Scalars['Bytes']>;
  erc20_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_contains?: InputMaybe<Scalars['Bytes']>;
  erc20_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DailySwappedTo_filter>>>;
  or?: InputMaybe<Array<InputMaybe<DailySwappedTo_filter>>>;
};

export type DailySwappedTo_orderBy =
  | 'id'
  | 'day'
  | 'day__id'
  | 'day__createdAccounts'
  | 'day__deployedAccounts'
  | 'day__activeAccounts'
  | 'day__transactions'
  | 'day__gasSponsored'
  | 'erc20'
  | 'amount';

export type Day = {
  /** day start timestamp concat 0x0000 */
  id: Scalars['Bytes'];
  createdAccounts: Scalars['Int'];
  deployedAccounts: Scalars['Int'];
  activeAccounts: Scalars['Int'];
  transactions: Scalars['Int'];
  gasSponsored: Scalars['BigInt'];
  investFlow: Array<DailyEarnFlow>;
  swappedTo: Array<DailySwappedTo>;
};


export type DayinvestFlowArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailyEarnFlow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DailyEarnFlow_filter>;
};


export type DayswappedToArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DailySwappedTo_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<DailySwappedTo_filter>;
};

export type DayAccount = {
  /** account.id.concat(day.id) */
  id: Scalars['Bytes'];
  account: ClaveAccount;
  day: Day;
};

export type DayAccount_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  account?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<ClaveAccount_filter>;
  day?: InputMaybe<Scalars['String']>;
  day_not?: InputMaybe<Scalars['String']>;
  day_gt?: InputMaybe<Scalars['String']>;
  day_lt?: InputMaybe<Scalars['String']>;
  day_gte?: InputMaybe<Scalars['String']>;
  day_lte?: InputMaybe<Scalars['String']>;
  day_in?: InputMaybe<Array<Scalars['String']>>;
  day_not_in?: InputMaybe<Array<Scalars['String']>>;
  day_contains?: InputMaybe<Scalars['String']>;
  day_contains_nocase?: InputMaybe<Scalars['String']>;
  day_not_contains?: InputMaybe<Scalars['String']>;
  day_not_contains_nocase?: InputMaybe<Scalars['String']>;
  day_starts_with?: InputMaybe<Scalars['String']>;
  day_starts_with_nocase?: InputMaybe<Scalars['String']>;
  day_not_starts_with?: InputMaybe<Scalars['String']>;
  day_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  day_ends_with?: InputMaybe<Scalars['String']>;
  day_ends_with_nocase?: InputMaybe<Scalars['String']>;
  day_not_ends_with?: InputMaybe<Scalars['String']>;
  day_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  day_?: InputMaybe<Day_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DayAccount_filter>>>;
  or?: InputMaybe<Array<InputMaybe<DayAccount_filter>>>;
};

export type DayAccount_orderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'account__creationDate'
  | 'account__deployDate'
  | 'account__recoveryCount'
  | 'account__hasRecovery'
  | 'account__isRecovering'
  | 'account__txCount'
  | 'account__implementation'
  | 'day'
  | 'day__id'
  | 'day__createdAccounts'
  | 'day__deployedAccounts'
  | 'day__activeAccounts'
  | 'day__transactions'
  | 'day__gasSponsored';

export type Day_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  createdAccounts?: InputMaybe<Scalars['Int']>;
  createdAccounts_not?: InputMaybe<Scalars['Int']>;
  createdAccounts_gt?: InputMaybe<Scalars['Int']>;
  createdAccounts_lt?: InputMaybe<Scalars['Int']>;
  createdAccounts_gte?: InputMaybe<Scalars['Int']>;
  createdAccounts_lte?: InputMaybe<Scalars['Int']>;
  createdAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  createdAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  deployedAccounts?: InputMaybe<Scalars['Int']>;
  deployedAccounts_not?: InputMaybe<Scalars['Int']>;
  deployedAccounts_gt?: InputMaybe<Scalars['Int']>;
  deployedAccounts_lt?: InputMaybe<Scalars['Int']>;
  deployedAccounts_gte?: InputMaybe<Scalars['Int']>;
  deployedAccounts_lte?: InputMaybe<Scalars['Int']>;
  deployedAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  deployedAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  activeAccounts?: InputMaybe<Scalars['Int']>;
  activeAccounts_not?: InputMaybe<Scalars['Int']>;
  activeAccounts_gt?: InputMaybe<Scalars['Int']>;
  activeAccounts_lt?: InputMaybe<Scalars['Int']>;
  activeAccounts_gte?: InputMaybe<Scalars['Int']>;
  activeAccounts_lte?: InputMaybe<Scalars['Int']>;
  activeAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  activeAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  transactions?: InputMaybe<Scalars['Int']>;
  transactions_not?: InputMaybe<Scalars['Int']>;
  transactions_gt?: InputMaybe<Scalars['Int']>;
  transactions_lt?: InputMaybe<Scalars['Int']>;
  transactions_gte?: InputMaybe<Scalars['Int']>;
  transactions_lte?: InputMaybe<Scalars['Int']>;
  transactions_in?: InputMaybe<Array<Scalars['Int']>>;
  transactions_not_in?: InputMaybe<Array<Scalars['Int']>>;
  gasSponsored?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_not?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_gt?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_lt?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_gte?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_lte?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_in?: InputMaybe<Array<Scalars['BigInt']>>;
  gasSponsored_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  investFlow_?: InputMaybe<DailyEarnFlow_filter>;
  swappedTo_?: InputMaybe<DailySwappedTo_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Day_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Day_filter>>>;
};

export type Day_orderBy =
  | 'id'
  | 'createdAccounts'
  | 'deployedAccounts'
  | 'activeAccounts'
  | 'transactions'
  | 'gasSponsored'
  | 'investFlow'
  | 'swappedTo';

export type EarnPosition = {
  /** account.id.concat(pool).concat(token) */
  id: Scalars['Bytes'];
  account: ClaveAccount;
  pool: Scalars['Bytes'];
  token: Scalars['Bytes'];
  protocol: EarnProtocol;
  invested: Scalars['BigInt'];
  compoundGain: Scalars['BigInt'];
  normalGain: Scalars['BigInt'];
};

export type EarnPosition_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  account?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<ClaveAccount_filter>;
  pool?: InputMaybe<Scalars['Bytes']>;
  pool_not?: InputMaybe<Scalars['Bytes']>;
  pool_gt?: InputMaybe<Scalars['Bytes']>;
  pool_lt?: InputMaybe<Scalars['Bytes']>;
  pool_gte?: InputMaybe<Scalars['Bytes']>;
  pool_lte?: InputMaybe<Scalars['Bytes']>;
  pool_in?: InputMaybe<Array<Scalars['Bytes']>>;
  pool_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  pool_contains?: InputMaybe<Scalars['Bytes']>;
  pool_not_contains?: InputMaybe<Scalars['Bytes']>;
  token?: InputMaybe<Scalars['Bytes']>;
  token_not?: InputMaybe<Scalars['Bytes']>;
  token_gt?: InputMaybe<Scalars['Bytes']>;
  token_lt?: InputMaybe<Scalars['Bytes']>;
  token_gte?: InputMaybe<Scalars['Bytes']>;
  token_lte?: InputMaybe<Scalars['Bytes']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token_contains?: InputMaybe<Scalars['Bytes']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']>;
  protocol?: InputMaybe<EarnProtocol>;
  protocol_not?: InputMaybe<EarnProtocol>;
  protocol_in?: InputMaybe<Array<EarnProtocol>>;
  protocol_not_in?: InputMaybe<Array<EarnProtocol>>;
  invested?: InputMaybe<Scalars['BigInt']>;
  invested_not?: InputMaybe<Scalars['BigInt']>;
  invested_gt?: InputMaybe<Scalars['BigInt']>;
  invested_lt?: InputMaybe<Scalars['BigInt']>;
  invested_gte?: InputMaybe<Scalars['BigInt']>;
  invested_lte?: InputMaybe<Scalars['BigInt']>;
  invested_in?: InputMaybe<Array<Scalars['BigInt']>>;
  invested_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  compoundGain?: InputMaybe<Scalars['BigInt']>;
  compoundGain_not?: InputMaybe<Scalars['BigInt']>;
  compoundGain_gt?: InputMaybe<Scalars['BigInt']>;
  compoundGain_lt?: InputMaybe<Scalars['BigInt']>;
  compoundGain_gte?: InputMaybe<Scalars['BigInt']>;
  compoundGain_lte?: InputMaybe<Scalars['BigInt']>;
  compoundGain_in?: InputMaybe<Array<Scalars['BigInt']>>;
  compoundGain_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  normalGain?: InputMaybe<Scalars['BigInt']>;
  normalGain_not?: InputMaybe<Scalars['BigInt']>;
  normalGain_gt?: InputMaybe<Scalars['BigInt']>;
  normalGain_lt?: InputMaybe<Scalars['BigInt']>;
  normalGain_gte?: InputMaybe<Scalars['BigInt']>;
  normalGain_lte?: InputMaybe<Scalars['BigInt']>;
  normalGain_in?: InputMaybe<Array<Scalars['BigInt']>>;
  normalGain_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<EarnPosition_filter>>>;
  or?: InputMaybe<Array<InputMaybe<EarnPosition_filter>>>;
};

export type EarnPosition_orderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'account__creationDate'
  | 'account__deployDate'
  | 'account__recoveryCount'
  | 'account__hasRecovery'
  | 'account__isRecovering'
  | 'account__txCount'
  | 'account__implementation'
  | 'pool'
  | 'token'
  | 'protocol'
  | 'invested'
  | 'compoundGain'
  | 'normalGain';

export type EarnProtocol =
  | 'Koi'
  | 'SyncSwap'
  | 'ZeroLend'
  | 'Clave'
  | 'Meow'
  | 'Venus';

export type InAppSwap = {
  id: Scalars['Bytes'];
  account: ClaveAccount;
  tokenIn: Scalars['Bytes'];
  amountIn: Scalars['BigInt'];
  tokenOut: Scalars['Bytes'];
  amountOut: Scalars['BigInt'];
  date: Scalars['BigInt'];
};

export type InAppSwap_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  account?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<ClaveAccount_filter>;
  tokenIn?: InputMaybe<Scalars['Bytes']>;
  tokenIn_not?: InputMaybe<Scalars['Bytes']>;
  tokenIn_gt?: InputMaybe<Scalars['Bytes']>;
  tokenIn_lt?: InputMaybe<Scalars['Bytes']>;
  tokenIn_gte?: InputMaybe<Scalars['Bytes']>;
  tokenIn_lte?: InputMaybe<Scalars['Bytes']>;
  tokenIn_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tokenIn_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tokenIn_contains?: InputMaybe<Scalars['Bytes']>;
  tokenIn_not_contains?: InputMaybe<Scalars['Bytes']>;
  amountIn?: InputMaybe<Scalars['BigInt']>;
  amountIn_not?: InputMaybe<Scalars['BigInt']>;
  amountIn_gt?: InputMaybe<Scalars['BigInt']>;
  amountIn_lt?: InputMaybe<Scalars['BigInt']>;
  amountIn_gte?: InputMaybe<Scalars['BigInt']>;
  amountIn_lte?: InputMaybe<Scalars['BigInt']>;
  amountIn_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountIn_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenOut?: InputMaybe<Scalars['Bytes']>;
  tokenOut_not?: InputMaybe<Scalars['Bytes']>;
  tokenOut_gt?: InputMaybe<Scalars['Bytes']>;
  tokenOut_lt?: InputMaybe<Scalars['Bytes']>;
  tokenOut_gte?: InputMaybe<Scalars['Bytes']>;
  tokenOut_lte?: InputMaybe<Scalars['Bytes']>;
  tokenOut_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tokenOut_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tokenOut_contains?: InputMaybe<Scalars['Bytes']>;
  tokenOut_not_contains?: InputMaybe<Scalars['Bytes']>;
  amountOut?: InputMaybe<Scalars['BigInt']>;
  amountOut_not?: InputMaybe<Scalars['BigInt']>;
  amountOut_gt?: InputMaybe<Scalars['BigInt']>;
  amountOut_lt?: InputMaybe<Scalars['BigInt']>;
  amountOut_gte?: InputMaybe<Scalars['BigInt']>;
  amountOut_lte?: InputMaybe<Scalars['BigInt']>;
  amountOut_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOut_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  date?: InputMaybe<Scalars['BigInt']>;
  date_not?: InputMaybe<Scalars['BigInt']>;
  date_gt?: InputMaybe<Scalars['BigInt']>;
  date_lt?: InputMaybe<Scalars['BigInt']>;
  date_gte?: InputMaybe<Scalars['BigInt']>;
  date_lte?: InputMaybe<Scalars['BigInt']>;
  date_in?: InputMaybe<Array<Scalars['BigInt']>>;
  date_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<InAppSwap_filter>>>;
  or?: InputMaybe<Array<InputMaybe<InAppSwap_filter>>>;
};

export type InAppSwap_orderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'account__creationDate'
  | 'account__deployDate'
  | 'account__recoveryCount'
  | 'account__hasRecovery'
  | 'account__isRecovering'
  | 'account__txCount'
  | 'account__implementation'
  | 'tokenIn'
  | 'amountIn'
  | 'tokenOut'
  | 'amountOut'
  | 'date';

export type Month = {
  /** month start timestamp concat 0x00 */
  id: Scalars['Bytes'];
  createdAccounts: Scalars['Int'];
  deployedAccounts: Scalars['Int'];
  activeAccounts: Scalars['Int'];
  transactions: Scalars['Int'];
  gasSponsored: Scalars['BigInt'];
  investFlow: Array<MonthlyEarnFlow>;
  swappedTo: Array<MonthlySwappedTo>;
};


export type MonthinvestFlowArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MonthlyEarnFlow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MonthlyEarnFlow_filter>;
};


export type MonthswappedToArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MonthlySwappedTo_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MonthlySwappedTo_filter>;
};

export type MonthAccount = {
  /** account.id.concat(month.id) */
  id: Scalars['Bytes'];
  account: ClaveAccount;
  month: Month;
};

export type MonthAccount_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  account?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<ClaveAccount_filter>;
  month?: InputMaybe<Scalars['String']>;
  month_not?: InputMaybe<Scalars['String']>;
  month_gt?: InputMaybe<Scalars['String']>;
  month_lt?: InputMaybe<Scalars['String']>;
  month_gte?: InputMaybe<Scalars['String']>;
  month_lte?: InputMaybe<Scalars['String']>;
  month_in?: InputMaybe<Array<Scalars['String']>>;
  month_not_in?: InputMaybe<Array<Scalars['String']>>;
  month_contains?: InputMaybe<Scalars['String']>;
  month_contains_nocase?: InputMaybe<Scalars['String']>;
  month_not_contains?: InputMaybe<Scalars['String']>;
  month_not_contains_nocase?: InputMaybe<Scalars['String']>;
  month_starts_with?: InputMaybe<Scalars['String']>;
  month_starts_with_nocase?: InputMaybe<Scalars['String']>;
  month_not_starts_with?: InputMaybe<Scalars['String']>;
  month_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  month_ends_with?: InputMaybe<Scalars['String']>;
  month_ends_with_nocase?: InputMaybe<Scalars['String']>;
  month_not_ends_with?: InputMaybe<Scalars['String']>;
  month_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  month_?: InputMaybe<Month_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MonthAccount_filter>>>;
  or?: InputMaybe<Array<InputMaybe<MonthAccount_filter>>>;
};

export type MonthAccount_orderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'account__creationDate'
  | 'account__deployDate'
  | 'account__recoveryCount'
  | 'account__hasRecovery'
  | 'account__isRecovering'
  | 'account__txCount'
  | 'account__implementation'
  | 'month'
  | 'month__id'
  | 'month__createdAccounts'
  | 'month__deployedAccounts'
  | 'month__activeAccounts'
  | 'month__transactions'
  | 'month__gasSponsored';

export type Month_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  createdAccounts?: InputMaybe<Scalars['Int']>;
  createdAccounts_not?: InputMaybe<Scalars['Int']>;
  createdAccounts_gt?: InputMaybe<Scalars['Int']>;
  createdAccounts_lt?: InputMaybe<Scalars['Int']>;
  createdAccounts_gte?: InputMaybe<Scalars['Int']>;
  createdAccounts_lte?: InputMaybe<Scalars['Int']>;
  createdAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  createdAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  deployedAccounts?: InputMaybe<Scalars['Int']>;
  deployedAccounts_not?: InputMaybe<Scalars['Int']>;
  deployedAccounts_gt?: InputMaybe<Scalars['Int']>;
  deployedAccounts_lt?: InputMaybe<Scalars['Int']>;
  deployedAccounts_gte?: InputMaybe<Scalars['Int']>;
  deployedAccounts_lte?: InputMaybe<Scalars['Int']>;
  deployedAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  deployedAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  activeAccounts?: InputMaybe<Scalars['Int']>;
  activeAccounts_not?: InputMaybe<Scalars['Int']>;
  activeAccounts_gt?: InputMaybe<Scalars['Int']>;
  activeAccounts_lt?: InputMaybe<Scalars['Int']>;
  activeAccounts_gte?: InputMaybe<Scalars['Int']>;
  activeAccounts_lte?: InputMaybe<Scalars['Int']>;
  activeAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  activeAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  transactions?: InputMaybe<Scalars['Int']>;
  transactions_not?: InputMaybe<Scalars['Int']>;
  transactions_gt?: InputMaybe<Scalars['Int']>;
  transactions_lt?: InputMaybe<Scalars['Int']>;
  transactions_gte?: InputMaybe<Scalars['Int']>;
  transactions_lte?: InputMaybe<Scalars['Int']>;
  transactions_in?: InputMaybe<Array<Scalars['Int']>>;
  transactions_not_in?: InputMaybe<Array<Scalars['Int']>>;
  gasSponsored?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_not?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_gt?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_lt?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_gte?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_lte?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_in?: InputMaybe<Array<Scalars['BigInt']>>;
  gasSponsored_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  investFlow_?: InputMaybe<MonthlyEarnFlow_filter>;
  swappedTo_?: InputMaybe<MonthlySwappedTo_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Month_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Month_filter>>>;
};

export type Month_orderBy =
  | 'id'
  | 'createdAccounts'
  | 'deployedAccounts'
  | 'activeAccounts'
  | 'transactions'
  | 'gasSponsored'
  | 'investFlow'
  | 'swappedTo';

export type MonthlyEarnFlow = {
  /** month.id.concat(erc20).concat(protocol) */
  id: Scalars['Bytes'];
  month: Month;
  erc20: Scalars['Bytes'];
  protocol: EarnProtocol;
  amountIn: Scalars['BigInt'];
  amountOut: Scalars['BigInt'];
  claimedGain: Scalars['BigInt'];
};

export type MonthlyEarnFlow_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  month?: InputMaybe<Scalars['String']>;
  month_not?: InputMaybe<Scalars['String']>;
  month_gt?: InputMaybe<Scalars['String']>;
  month_lt?: InputMaybe<Scalars['String']>;
  month_gte?: InputMaybe<Scalars['String']>;
  month_lte?: InputMaybe<Scalars['String']>;
  month_in?: InputMaybe<Array<Scalars['String']>>;
  month_not_in?: InputMaybe<Array<Scalars['String']>>;
  month_contains?: InputMaybe<Scalars['String']>;
  month_contains_nocase?: InputMaybe<Scalars['String']>;
  month_not_contains?: InputMaybe<Scalars['String']>;
  month_not_contains_nocase?: InputMaybe<Scalars['String']>;
  month_starts_with?: InputMaybe<Scalars['String']>;
  month_starts_with_nocase?: InputMaybe<Scalars['String']>;
  month_not_starts_with?: InputMaybe<Scalars['String']>;
  month_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  month_ends_with?: InputMaybe<Scalars['String']>;
  month_ends_with_nocase?: InputMaybe<Scalars['String']>;
  month_not_ends_with?: InputMaybe<Scalars['String']>;
  month_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  month_?: InputMaybe<Month_filter>;
  erc20?: InputMaybe<Scalars['Bytes']>;
  erc20_not?: InputMaybe<Scalars['Bytes']>;
  erc20_gt?: InputMaybe<Scalars['Bytes']>;
  erc20_lt?: InputMaybe<Scalars['Bytes']>;
  erc20_gte?: InputMaybe<Scalars['Bytes']>;
  erc20_lte?: InputMaybe<Scalars['Bytes']>;
  erc20_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_contains?: InputMaybe<Scalars['Bytes']>;
  erc20_not_contains?: InputMaybe<Scalars['Bytes']>;
  protocol?: InputMaybe<EarnProtocol>;
  protocol_not?: InputMaybe<EarnProtocol>;
  protocol_in?: InputMaybe<Array<EarnProtocol>>;
  protocol_not_in?: InputMaybe<Array<EarnProtocol>>;
  amountIn?: InputMaybe<Scalars['BigInt']>;
  amountIn_not?: InputMaybe<Scalars['BigInt']>;
  amountIn_gt?: InputMaybe<Scalars['BigInt']>;
  amountIn_lt?: InputMaybe<Scalars['BigInt']>;
  amountIn_gte?: InputMaybe<Scalars['BigInt']>;
  amountIn_lte?: InputMaybe<Scalars['BigInt']>;
  amountIn_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountIn_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOut?: InputMaybe<Scalars['BigInt']>;
  amountOut_not?: InputMaybe<Scalars['BigInt']>;
  amountOut_gt?: InputMaybe<Scalars['BigInt']>;
  amountOut_lt?: InputMaybe<Scalars['BigInt']>;
  amountOut_gte?: InputMaybe<Scalars['BigInt']>;
  amountOut_lte?: InputMaybe<Scalars['BigInt']>;
  amountOut_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOut_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  claimedGain?: InputMaybe<Scalars['BigInt']>;
  claimedGain_not?: InputMaybe<Scalars['BigInt']>;
  claimedGain_gt?: InputMaybe<Scalars['BigInt']>;
  claimedGain_lt?: InputMaybe<Scalars['BigInt']>;
  claimedGain_gte?: InputMaybe<Scalars['BigInt']>;
  claimedGain_lte?: InputMaybe<Scalars['BigInt']>;
  claimedGain_in?: InputMaybe<Array<Scalars['BigInt']>>;
  claimedGain_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MonthlyEarnFlow_filter>>>;
  or?: InputMaybe<Array<InputMaybe<MonthlyEarnFlow_filter>>>;
};

export type MonthlyEarnFlow_orderBy =
  | 'id'
  | 'month'
  | 'month__id'
  | 'month__createdAccounts'
  | 'month__deployedAccounts'
  | 'month__activeAccounts'
  | 'month__transactions'
  | 'month__gasSponsored'
  | 'erc20'
  | 'protocol'
  | 'amountIn'
  | 'amountOut'
  | 'claimedGain';

export type MonthlySwappedTo = {
  /** month.id.concat(erc20) */
  id: Scalars['Bytes'];
  month: Month;
  erc20: Scalars['Bytes'];
  amount: Scalars['BigInt'];
};

export type MonthlySwappedTo_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  month?: InputMaybe<Scalars['String']>;
  month_not?: InputMaybe<Scalars['String']>;
  month_gt?: InputMaybe<Scalars['String']>;
  month_lt?: InputMaybe<Scalars['String']>;
  month_gte?: InputMaybe<Scalars['String']>;
  month_lte?: InputMaybe<Scalars['String']>;
  month_in?: InputMaybe<Array<Scalars['String']>>;
  month_not_in?: InputMaybe<Array<Scalars['String']>>;
  month_contains?: InputMaybe<Scalars['String']>;
  month_contains_nocase?: InputMaybe<Scalars['String']>;
  month_not_contains?: InputMaybe<Scalars['String']>;
  month_not_contains_nocase?: InputMaybe<Scalars['String']>;
  month_starts_with?: InputMaybe<Scalars['String']>;
  month_starts_with_nocase?: InputMaybe<Scalars['String']>;
  month_not_starts_with?: InputMaybe<Scalars['String']>;
  month_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  month_ends_with?: InputMaybe<Scalars['String']>;
  month_ends_with_nocase?: InputMaybe<Scalars['String']>;
  month_not_ends_with?: InputMaybe<Scalars['String']>;
  month_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  month_?: InputMaybe<Month_filter>;
  erc20?: InputMaybe<Scalars['Bytes']>;
  erc20_not?: InputMaybe<Scalars['Bytes']>;
  erc20_gt?: InputMaybe<Scalars['Bytes']>;
  erc20_lt?: InputMaybe<Scalars['Bytes']>;
  erc20_gte?: InputMaybe<Scalars['Bytes']>;
  erc20_lte?: InputMaybe<Scalars['Bytes']>;
  erc20_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_contains?: InputMaybe<Scalars['Bytes']>;
  erc20_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MonthlySwappedTo_filter>>>;
  or?: InputMaybe<Array<InputMaybe<MonthlySwappedTo_filter>>>;
};

export type MonthlySwappedTo_orderBy =
  | 'id'
  | 'month'
  | 'month__id'
  | 'month__createdAccounts'
  | 'month__deployedAccounts'
  | 'month__activeAccounts'
  | 'month__transactions'
  | 'month__gasSponsored'
  | 'erc20'
  | 'amount';

export type Paymaster =
  | 'None'
  | 'ERC20'
  | 'Gasless';

export type ReferralFee = {
  /** account.id.refferred.id.concat(erc20) */
  id: Scalars['Bytes'];
  account: ClaveAccount;
  referred: ClaveAccount;
  erc20: Scalars['Bytes'];
  amount: Scalars['BigInt'];
};

export type ReferralFee_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  account?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<ClaveAccount_filter>;
  referred?: InputMaybe<Scalars['String']>;
  referred_not?: InputMaybe<Scalars['String']>;
  referred_gt?: InputMaybe<Scalars['String']>;
  referred_lt?: InputMaybe<Scalars['String']>;
  referred_gte?: InputMaybe<Scalars['String']>;
  referred_lte?: InputMaybe<Scalars['String']>;
  referred_in?: InputMaybe<Array<Scalars['String']>>;
  referred_not_in?: InputMaybe<Array<Scalars['String']>>;
  referred_contains?: InputMaybe<Scalars['String']>;
  referred_contains_nocase?: InputMaybe<Scalars['String']>;
  referred_not_contains?: InputMaybe<Scalars['String']>;
  referred_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referred_starts_with?: InputMaybe<Scalars['String']>;
  referred_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referred_not_starts_with?: InputMaybe<Scalars['String']>;
  referred_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referred_ends_with?: InputMaybe<Scalars['String']>;
  referred_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referred_not_ends_with?: InputMaybe<Scalars['String']>;
  referred_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referred_?: InputMaybe<ClaveAccount_filter>;
  erc20?: InputMaybe<Scalars['Bytes']>;
  erc20_not?: InputMaybe<Scalars['Bytes']>;
  erc20_gt?: InputMaybe<Scalars['Bytes']>;
  erc20_lt?: InputMaybe<Scalars['Bytes']>;
  erc20_gte?: InputMaybe<Scalars['Bytes']>;
  erc20_lte?: InputMaybe<Scalars['Bytes']>;
  erc20_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_contains?: InputMaybe<Scalars['Bytes']>;
  erc20_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ReferralFee_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ReferralFee_filter>>>;
};

export type ReferralFee_orderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'account__creationDate'
  | 'account__deployDate'
  | 'account__recoveryCount'
  | 'account__hasRecovery'
  | 'account__isRecovering'
  | 'account__txCount'
  | 'account__implementation'
  | 'referred'
  | 'referred__id'
  | 'referred__creationDate'
  | 'referred__deployDate'
  | 'referred__recoveryCount'
  | 'referred__hasRecovery'
  | 'referred__isRecovering'
  | 'referred__txCount'
  | 'referred__implementation'
  | 'erc20'
  | 'amount';

export type Total = {
  /** 0x746f74616c */
  id: Scalars['Bytes'];
  createdAccounts: Scalars['Int'];
  deployedAccounts: Scalars['Int'];
  transactions: Scalars['Int'];
  backedUp: Scalars['Int'];
  gasSponsored: Scalars['BigInt'];
};

export type Total_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  createdAccounts?: InputMaybe<Scalars['Int']>;
  createdAccounts_not?: InputMaybe<Scalars['Int']>;
  createdAccounts_gt?: InputMaybe<Scalars['Int']>;
  createdAccounts_lt?: InputMaybe<Scalars['Int']>;
  createdAccounts_gte?: InputMaybe<Scalars['Int']>;
  createdAccounts_lte?: InputMaybe<Scalars['Int']>;
  createdAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  createdAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  deployedAccounts?: InputMaybe<Scalars['Int']>;
  deployedAccounts_not?: InputMaybe<Scalars['Int']>;
  deployedAccounts_gt?: InputMaybe<Scalars['Int']>;
  deployedAccounts_lt?: InputMaybe<Scalars['Int']>;
  deployedAccounts_gte?: InputMaybe<Scalars['Int']>;
  deployedAccounts_lte?: InputMaybe<Scalars['Int']>;
  deployedAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  deployedAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  transactions?: InputMaybe<Scalars['Int']>;
  transactions_not?: InputMaybe<Scalars['Int']>;
  transactions_gt?: InputMaybe<Scalars['Int']>;
  transactions_lt?: InputMaybe<Scalars['Int']>;
  transactions_gte?: InputMaybe<Scalars['Int']>;
  transactions_lte?: InputMaybe<Scalars['Int']>;
  transactions_in?: InputMaybe<Array<Scalars['Int']>>;
  transactions_not_in?: InputMaybe<Array<Scalars['Int']>>;
  backedUp?: InputMaybe<Scalars['Int']>;
  backedUp_not?: InputMaybe<Scalars['Int']>;
  backedUp_gt?: InputMaybe<Scalars['Int']>;
  backedUp_lt?: InputMaybe<Scalars['Int']>;
  backedUp_gte?: InputMaybe<Scalars['Int']>;
  backedUp_lte?: InputMaybe<Scalars['Int']>;
  backedUp_in?: InputMaybe<Array<Scalars['Int']>>;
  backedUp_not_in?: InputMaybe<Array<Scalars['Int']>>;
  gasSponsored?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_not?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_gt?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_lt?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_gte?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_lte?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_in?: InputMaybe<Array<Scalars['BigInt']>>;
  gasSponsored_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Total_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Total_filter>>>;
};

export type Total_orderBy =
  | 'id'
  | 'createdAccounts'
  | 'deployedAccounts'
  | 'transactions'
  | 'backedUp'
  | 'gasSponsored';

export type Week = {
  /** week start timestamp */
  id: Scalars['Bytes'];
  createdAccounts: Scalars['Int'];
  deployedAccounts: Scalars['Int'];
  activeAccounts: Scalars['Int'];
  transactions: Scalars['Int'];
  gasSponsored: Scalars['BigInt'];
  investFlow: Array<WeeklyEarnFlow>;
  swappedTo: Array<WeeklySwappedTo>;
};


export type WeekinvestFlowArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WeeklyEarnFlow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WeeklyEarnFlow_filter>;
};


export type WeekswappedToArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WeeklySwappedTo_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WeeklySwappedTo_filter>;
};

export type WeekAccount = {
  /** account.id.concat(week.id) */
  id: Scalars['Bytes'];
  account: ClaveAccount;
  week: Week;
};

export type WeekAccount_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  account?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<ClaveAccount_filter>;
  week?: InputMaybe<Scalars['String']>;
  week_not?: InputMaybe<Scalars['String']>;
  week_gt?: InputMaybe<Scalars['String']>;
  week_lt?: InputMaybe<Scalars['String']>;
  week_gte?: InputMaybe<Scalars['String']>;
  week_lte?: InputMaybe<Scalars['String']>;
  week_in?: InputMaybe<Array<Scalars['String']>>;
  week_not_in?: InputMaybe<Array<Scalars['String']>>;
  week_contains?: InputMaybe<Scalars['String']>;
  week_contains_nocase?: InputMaybe<Scalars['String']>;
  week_not_contains?: InputMaybe<Scalars['String']>;
  week_not_contains_nocase?: InputMaybe<Scalars['String']>;
  week_starts_with?: InputMaybe<Scalars['String']>;
  week_starts_with_nocase?: InputMaybe<Scalars['String']>;
  week_not_starts_with?: InputMaybe<Scalars['String']>;
  week_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  week_ends_with?: InputMaybe<Scalars['String']>;
  week_ends_with_nocase?: InputMaybe<Scalars['String']>;
  week_not_ends_with?: InputMaybe<Scalars['String']>;
  week_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  week_?: InputMaybe<Week_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<WeekAccount_filter>>>;
  or?: InputMaybe<Array<InputMaybe<WeekAccount_filter>>>;
};

export type WeekAccount_orderBy =
  | 'id'
  | 'account'
  | 'account__id'
  | 'account__creationDate'
  | 'account__deployDate'
  | 'account__recoveryCount'
  | 'account__hasRecovery'
  | 'account__isRecovering'
  | 'account__txCount'
  | 'account__implementation'
  | 'week'
  | 'week__id'
  | 'week__createdAccounts'
  | 'week__deployedAccounts'
  | 'week__activeAccounts'
  | 'week__transactions'
  | 'week__gasSponsored';

export type Week_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  createdAccounts?: InputMaybe<Scalars['Int']>;
  createdAccounts_not?: InputMaybe<Scalars['Int']>;
  createdAccounts_gt?: InputMaybe<Scalars['Int']>;
  createdAccounts_lt?: InputMaybe<Scalars['Int']>;
  createdAccounts_gte?: InputMaybe<Scalars['Int']>;
  createdAccounts_lte?: InputMaybe<Scalars['Int']>;
  createdAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  createdAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  deployedAccounts?: InputMaybe<Scalars['Int']>;
  deployedAccounts_not?: InputMaybe<Scalars['Int']>;
  deployedAccounts_gt?: InputMaybe<Scalars['Int']>;
  deployedAccounts_lt?: InputMaybe<Scalars['Int']>;
  deployedAccounts_gte?: InputMaybe<Scalars['Int']>;
  deployedAccounts_lte?: InputMaybe<Scalars['Int']>;
  deployedAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  deployedAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  activeAccounts?: InputMaybe<Scalars['Int']>;
  activeAccounts_not?: InputMaybe<Scalars['Int']>;
  activeAccounts_gt?: InputMaybe<Scalars['Int']>;
  activeAccounts_lt?: InputMaybe<Scalars['Int']>;
  activeAccounts_gte?: InputMaybe<Scalars['Int']>;
  activeAccounts_lte?: InputMaybe<Scalars['Int']>;
  activeAccounts_in?: InputMaybe<Array<Scalars['Int']>>;
  activeAccounts_not_in?: InputMaybe<Array<Scalars['Int']>>;
  transactions?: InputMaybe<Scalars['Int']>;
  transactions_not?: InputMaybe<Scalars['Int']>;
  transactions_gt?: InputMaybe<Scalars['Int']>;
  transactions_lt?: InputMaybe<Scalars['Int']>;
  transactions_gte?: InputMaybe<Scalars['Int']>;
  transactions_lte?: InputMaybe<Scalars['Int']>;
  transactions_in?: InputMaybe<Array<Scalars['Int']>>;
  transactions_not_in?: InputMaybe<Array<Scalars['Int']>>;
  gasSponsored?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_not?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_gt?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_lt?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_gte?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_lte?: InputMaybe<Scalars['BigInt']>;
  gasSponsored_in?: InputMaybe<Array<Scalars['BigInt']>>;
  gasSponsored_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  investFlow_?: InputMaybe<WeeklyEarnFlow_filter>;
  swappedTo_?: InputMaybe<WeeklySwappedTo_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Week_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Week_filter>>>;
};

export type Week_orderBy =
  | 'id'
  | 'createdAccounts'
  | 'deployedAccounts'
  | 'activeAccounts'
  | 'transactions'
  | 'gasSponsored'
  | 'investFlow'
  | 'swappedTo';

export type WeeklyEarnFlow = {
  /** week.id.concat(erc20).concat(protocol) */
  id: Scalars['Bytes'];
  week: Week;
  erc20: Scalars['Bytes'];
  protocol: EarnProtocol;
  amountIn: Scalars['BigInt'];
  amountOut: Scalars['BigInt'];
  claimedGain: Scalars['BigInt'];
};

export type WeeklyEarnFlow_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  week?: InputMaybe<Scalars['String']>;
  week_not?: InputMaybe<Scalars['String']>;
  week_gt?: InputMaybe<Scalars['String']>;
  week_lt?: InputMaybe<Scalars['String']>;
  week_gte?: InputMaybe<Scalars['String']>;
  week_lte?: InputMaybe<Scalars['String']>;
  week_in?: InputMaybe<Array<Scalars['String']>>;
  week_not_in?: InputMaybe<Array<Scalars['String']>>;
  week_contains?: InputMaybe<Scalars['String']>;
  week_contains_nocase?: InputMaybe<Scalars['String']>;
  week_not_contains?: InputMaybe<Scalars['String']>;
  week_not_contains_nocase?: InputMaybe<Scalars['String']>;
  week_starts_with?: InputMaybe<Scalars['String']>;
  week_starts_with_nocase?: InputMaybe<Scalars['String']>;
  week_not_starts_with?: InputMaybe<Scalars['String']>;
  week_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  week_ends_with?: InputMaybe<Scalars['String']>;
  week_ends_with_nocase?: InputMaybe<Scalars['String']>;
  week_not_ends_with?: InputMaybe<Scalars['String']>;
  week_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  week_?: InputMaybe<Week_filter>;
  erc20?: InputMaybe<Scalars['Bytes']>;
  erc20_not?: InputMaybe<Scalars['Bytes']>;
  erc20_gt?: InputMaybe<Scalars['Bytes']>;
  erc20_lt?: InputMaybe<Scalars['Bytes']>;
  erc20_gte?: InputMaybe<Scalars['Bytes']>;
  erc20_lte?: InputMaybe<Scalars['Bytes']>;
  erc20_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_contains?: InputMaybe<Scalars['Bytes']>;
  erc20_not_contains?: InputMaybe<Scalars['Bytes']>;
  protocol?: InputMaybe<EarnProtocol>;
  protocol_not?: InputMaybe<EarnProtocol>;
  protocol_in?: InputMaybe<Array<EarnProtocol>>;
  protocol_not_in?: InputMaybe<Array<EarnProtocol>>;
  amountIn?: InputMaybe<Scalars['BigInt']>;
  amountIn_not?: InputMaybe<Scalars['BigInt']>;
  amountIn_gt?: InputMaybe<Scalars['BigInt']>;
  amountIn_lt?: InputMaybe<Scalars['BigInt']>;
  amountIn_gte?: InputMaybe<Scalars['BigInt']>;
  amountIn_lte?: InputMaybe<Scalars['BigInt']>;
  amountIn_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountIn_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOut?: InputMaybe<Scalars['BigInt']>;
  amountOut_not?: InputMaybe<Scalars['BigInt']>;
  amountOut_gt?: InputMaybe<Scalars['BigInt']>;
  amountOut_lt?: InputMaybe<Scalars['BigInt']>;
  amountOut_gte?: InputMaybe<Scalars['BigInt']>;
  amountOut_lte?: InputMaybe<Scalars['BigInt']>;
  amountOut_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOut_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  claimedGain?: InputMaybe<Scalars['BigInt']>;
  claimedGain_not?: InputMaybe<Scalars['BigInt']>;
  claimedGain_gt?: InputMaybe<Scalars['BigInt']>;
  claimedGain_lt?: InputMaybe<Scalars['BigInt']>;
  claimedGain_gte?: InputMaybe<Scalars['BigInt']>;
  claimedGain_lte?: InputMaybe<Scalars['BigInt']>;
  claimedGain_in?: InputMaybe<Array<Scalars['BigInt']>>;
  claimedGain_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<WeeklyEarnFlow_filter>>>;
  or?: InputMaybe<Array<InputMaybe<WeeklyEarnFlow_filter>>>;
};

export type WeeklyEarnFlow_orderBy =
  | 'id'
  | 'week'
  | 'week__id'
  | 'week__createdAccounts'
  | 'week__deployedAccounts'
  | 'week__activeAccounts'
  | 'week__transactions'
  | 'week__gasSponsored'
  | 'erc20'
  | 'protocol'
  | 'amountIn'
  | 'amountOut'
  | 'claimedGain';

export type WeeklySwappedTo = {
  /** week.id.concat(erc20) */
  id: Scalars['Bytes'];
  week: Week;
  erc20: Scalars['Bytes'];
  amount: Scalars['BigInt'];
};

export type WeeklySwappedTo_filter = {
  id?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  week?: InputMaybe<Scalars['String']>;
  week_not?: InputMaybe<Scalars['String']>;
  week_gt?: InputMaybe<Scalars['String']>;
  week_lt?: InputMaybe<Scalars['String']>;
  week_gte?: InputMaybe<Scalars['String']>;
  week_lte?: InputMaybe<Scalars['String']>;
  week_in?: InputMaybe<Array<Scalars['String']>>;
  week_not_in?: InputMaybe<Array<Scalars['String']>>;
  week_contains?: InputMaybe<Scalars['String']>;
  week_contains_nocase?: InputMaybe<Scalars['String']>;
  week_not_contains?: InputMaybe<Scalars['String']>;
  week_not_contains_nocase?: InputMaybe<Scalars['String']>;
  week_starts_with?: InputMaybe<Scalars['String']>;
  week_starts_with_nocase?: InputMaybe<Scalars['String']>;
  week_not_starts_with?: InputMaybe<Scalars['String']>;
  week_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  week_ends_with?: InputMaybe<Scalars['String']>;
  week_ends_with_nocase?: InputMaybe<Scalars['String']>;
  week_not_ends_with?: InputMaybe<Scalars['String']>;
  week_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  week_?: InputMaybe<Week_filter>;
  erc20?: InputMaybe<Scalars['Bytes']>;
  erc20_not?: InputMaybe<Scalars['Bytes']>;
  erc20_gt?: InputMaybe<Scalars['Bytes']>;
  erc20_lt?: InputMaybe<Scalars['Bytes']>;
  erc20_gte?: InputMaybe<Scalars['Bytes']>;
  erc20_lte?: InputMaybe<Scalars['Bytes']>;
  erc20_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  erc20_contains?: InputMaybe<Scalars['Bytes']>;
  erc20_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<WeeklySwappedTo_filter>>>;
  or?: InputMaybe<Array<InputMaybe<WeeklySwappedTo_filter>>>;
};

export type WeeklySwappedTo_orderBy =
  | 'id'
  | 'week'
  | 'week__id'
  | 'week__createdAccounts'
  | 'week__deployedAccounts'
  | 'week__activeAccounts'
  | 'week__transactions'
  | 'week__gasSponsored'
  | 'erc20'
  | 'amount';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Query: ResolverTypeWrapper<{}>;
  Subscription: ResolverTypeWrapper<{}>;
  Aggregation_interval: Aggregation_interval;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']>;
  ClaveAccount: ResolverTypeWrapper<ClaveAccount>;
  ClaveAccount_filter: ClaveAccount_filter;
  ClaveAccount_orderBy: ClaveAccount_orderBy;
  ERC20: ResolverTypeWrapper<ERC20>;
  ERC20Balance: ResolverTypeWrapper<ERC20Balance>;
  ERC20Balance_filter: ERC20Balance_filter;
  ERC20Balance_orderBy: ERC20Balance_orderBy;
  ERC20_filter: ERC20_filter;
  ERC20_orderBy: ERC20_orderBy;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int8: ResolverTypeWrapper<Scalars['Int8']>;
  OrderDirection: OrderDirection;
  String: ResolverTypeWrapper<Scalars['String']>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']>;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
  Cashback: ResolverTypeWrapper<Cashback>;
  Cashback_filter: Cashback_filter;
  Cashback_orderBy: Cashback_orderBy;
  ClaveTransaction: ResolverTypeWrapper<ClaveTransaction>;
  ClaveTransaction_filter: ClaveTransaction_filter;
  ClaveTransaction_orderBy: ClaveTransaction_orderBy;
  DailyEarnFlow: ResolverTypeWrapper<DailyEarnFlow>;
  DailyEarnFlow_filter: DailyEarnFlow_filter;
  DailyEarnFlow_orderBy: DailyEarnFlow_orderBy;
  DailySwappedTo: ResolverTypeWrapper<DailySwappedTo>;
  DailySwappedTo_filter: DailySwappedTo_filter;
  DailySwappedTo_orderBy: DailySwappedTo_orderBy;
  Day: ResolverTypeWrapper<Day>;
  DayAccount: ResolverTypeWrapper<DayAccount>;
  DayAccount_filter: DayAccount_filter;
  DayAccount_orderBy: DayAccount_orderBy;
  Day_filter: Day_filter;
  Day_orderBy: Day_orderBy;
  EarnPosition: ResolverTypeWrapper<EarnPosition>;
  EarnPosition_filter: EarnPosition_filter;
  EarnPosition_orderBy: EarnPosition_orderBy;
  EarnProtocol: EarnProtocol;
  InAppSwap: ResolverTypeWrapper<InAppSwap>;
  InAppSwap_filter: InAppSwap_filter;
  InAppSwap_orderBy: InAppSwap_orderBy;
  Month: ResolverTypeWrapper<Month>;
  MonthAccount: ResolverTypeWrapper<MonthAccount>;
  MonthAccount_filter: MonthAccount_filter;
  MonthAccount_orderBy: MonthAccount_orderBy;
  Month_filter: Month_filter;
  Month_orderBy: Month_orderBy;
  MonthlyEarnFlow: ResolverTypeWrapper<MonthlyEarnFlow>;
  MonthlyEarnFlow_filter: MonthlyEarnFlow_filter;
  MonthlyEarnFlow_orderBy: MonthlyEarnFlow_orderBy;
  MonthlySwappedTo: ResolverTypeWrapper<MonthlySwappedTo>;
  MonthlySwappedTo_filter: MonthlySwappedTo_filter;
  MonthlySwappedTo_orderBy: MonthlySwappedTo_orderBy;
  Paymaster: Paymaster;
  ReferralFee: ResolverTypeWrapper<ReferralFee>;
  ReferralFee_filter: ReferralFee_filter;
  ReferralFee_orderBy: ReferralFee_orderBy;
  Total: ResolverTypeWrapper<Total>;
  Total_filter: Total_filter;
  Total_orderBy: Total_orderBy;
  Week: ResolverTypeWrapper<Week>;
  WeekAccount: ResolverTypeWrapper<WeekAccount>;
  WeekAccount_filter: WeekAccount_filter;
  WeekAccount_orderBy: WeekAccount_orderBy;
  Week_filter: Week_filter;
  Week_orderBy: Week_orderBy;
  WeeklyEarnFlow: ResolverTypeWrapper<WeeklyEarnFlow>;
  WeeklyEarnFlow_filter: WeeklyEarnFlow_filter;
  WeeklyEarnFlow_orderBy: WeeklyEarnFlow_orderBy;
  WeeklySwappedTo: ResolverTypeWrapper<WeeklySwappedTo>;
  WeeklySwappedTo_filter: WeeklySwappedTo_filter;
  WeeklySwappedTo_orderBy: WeeklySwappedTo_orderBy;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Query: {};
  Subscription: {};
  BigDecimal: Scalars['BigDecimal'];
  BigInt: Scalars['BigInt'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: Scalars['Boolean'];
  Bytes: Scalars['Bytes'];
  ClaveAccount: ClaveAccount;
  ClaveAccount_filter: ClaveAccount_filter;
  ERC20: ERC20;
  ERC20Balance: ERC20Balance;
  ERC20Balance_filter: ERC20Balance_filter;
  ERC20_filter: ERC20_filter;
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Int8: Scalars['Int8'];
  String: Scalars['String'];
  Timestamp: Scalars['Timestamp'];
  _Block_: _Block_;
  _Meta_: _Meta_;
  Cashback: Cashback;
  Cashback_filter: Cashback_filter;
  ClaveTransaction: ClaveTransaction;
  ClaveTransaction_filter: ClaveTransaction_filter;
  DailyEarnFlow: DailyEarnFlow;
  DailyEarnFlow_filter: DailyEarnFlow_filter;
  DailySwappedTo: DailySwappedTo;
  DailySwappedTo_filter: DailySwappedTo_filter;
  Day: Day;
  DayAccount: DayAccount;
  DayAccount_filter: DayAccount_filter;
  Day_filter: Day_filter;
  EarnPosition: EarnPosition;
  EarnPosition_filter: EarnPosition_filter;
  InAppSwap: InAppSwap;
  InAppSwap_filter: InAppSwap_filter;
  Month: Month;
  MonthAccount: MonthAccount;
  MonthAccount_filter: MonthAccount_filter;
  Month_filter: Month_filter;
  MonthlyEarnFlow: MonthlyEarnFlow;
  MonthlyEarnFlow_filter: MonthlyEarnFlow_filter;
  MonthlySwappedTo: MonthlySwappedTo;
  MonthlySwappedTo_filter: MonthlySwappedTo_filter;
  ReferralFee: ReferralFee;
  ReferralFee_filter: ReferralFee_filter;
  Total: Total;
  Total_filter: Total_filter;
  Week: Week;
  WeekAccount: WeekAccount;
  WeekAccount_filter: WeekAccount_filter;
  Week_filter: Week_filter;
  WeeklyEarnFlow: WeeklyEarnFlow;
  WeeklyEarnFlow_filter: WeeklyEarnFlow_filter;
  WeeklySwappedTo: WeeklySwappedTo;
  WeeklySwappedTo_filter: WeeklySwappedTo_filter;
}>;

export type entityDirectiveArgs = { };

export type entityDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = entityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type subgraphIdDirectiveArgs = {
  id: Scalars['String'];
};

export type subgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = subgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type derivedFromDirectiveArgs = {
  field: Scalars['String'];
};

export type derivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = derivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type QueryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  claveAccount?: Resolver<Maybe<ResolversTypes['ClaveAccount']>, ParentType, ContextType, RequireFields<QueryclaveAccountArgs, 'id' | 'subgraphError'>>;
  claveAccounts?: Resolver<Array<ResolversTypes['ClaveAccount']>, ParentType, ContextType, RequireFields<QueryclaveAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  erc20?: Resolver<Maybe<ResolversTypes['ERC20']>, ParentType, ContextType, RequireFields<Queryerc20Args, 'id' | 'subgraphError'>>;
  erc20S?: Resolver<Array<ResolversTypes['ERC20']>, ParentType, ContextType, RequireFields<Queryerc20SArgs, 'skip' | 'first' | 'subgraphError'>>;
  erc20Balance?: Resolver<Maybe<ResolversTypes['ERC20Balance']>, ParentType, ContextType, RequireFields<Queryerc20BalanceArgs, 'id' | 'subgraphError'>>;
  erc20Balances?: Resolver<Array<ResolversTypes['ERC20Balance']>, ParentType, ContextType, RequireFields<Queryerc20BalancesArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>;
  total?: Resolver<Maybe<ResolversTypes['Total']>, ParentType, ContextType, RequireFields<QuerytotalArgs, 'id' | 'subgraphError'>>;
  totals?: Resolver<Array<ResolversTypes['Total']>, ParentType, ContextType, RequireFields<QuerytotalsArgs, 'skip' | 'first' | 'subgraphError'>>;
  day?: Resolver<Maybe<ResolversTypes['Day']>, ParentType, ContextType, RequireFields<QuerydayArgs, 'id' | 'subgraphError'>>;
  days?: Resolver<Array<ResolversTypes['Day']>, ParentType, ContextType, RequireFields<QuerydaysArgs, 'skip' | 'first' | 'subgraphError'>>;
  week?: Resolver<Maybe<ResolversTypes['Week']>, ParentType, ContextType, RequireFields<QueryweekArgs, 'id' | 'subgraphError'>>;
  weeks?: Resolver<Array<ResolversTypes['Week']>, ParentType, ContextType, RequireFields<QueryweeksArgs, 'skip' | 'first' | 'subgraphError'>>;
  month?: Resolver<Maybe<ResolversTypes['Month']>, ParentType, ContextType, RequireFields<QuerymonthArgs, 'id' | 'subgraphError'>>;
  months?: Resolver<Array<ResolversTypes['Month']>, ParentType, ContextType, RequireFields<QuerymonthsArgs, 'skip' | 'first' | 'subgraphError'>>;
  earnPosition?: Resolver<Maybe<ResolversTypes['EarnPosition']>, ParentType, ContextType, RequireFields<QueryearnPositionArgs, 'id' | 'subgraphError'>>;
  earnPositions?: Resolver<Array<ResolversTypes['EarnPosition']>, ParentType, ContextType, RequireFields<QueryearnPositionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  dailyEarnFlow?: Resolver<Maybe<ResolversTypes['DailyEarnFlow']>, ParentType, ContextType, RequireFields<QuerydailyEarnFlowArgs, 'id' | 'subgraphError'>>;
  dailyEarnFlows?: Resolver<Array<ResolversTypes['DailyEarnFlow']>, ParentType, ContextType, RequireFields<QuerydailyEarnFlowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  weeklyEarnFlow?: Resolver<Maybe<ResolversTypes['WeeklyEarnFlow']>, ParentType, ContextType, RequireFields<QueryweeklyEarnFlowArgs, 'id' | 'subgraphError'>>;
  weeklyEarnFlows?: Resolver<Array<ResolversTypes['WeeklyEarnFlow']>, ParentType, ContextType, RequireFields<QueryweeklyEarnFlowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  monthlyEarnFlow?: Resolver<Maybe<ResolversTypes['MonthlyEarnFlow']>, ParentType, ContextType, RequireFields<QuerymonthlyEarnFlowArgs, 'id' | 'subgraphError'>>;
  monthlyEarnFlows?: Resolver<Array<ResolversTypes['MonthlyEarnFlow']>, ParentType, ContextType, RequireFields<QuerymonthlyEarnFlowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  dailySwappedTo?: Resolver<Maybe<ResolversTypes['DailySwappedTo']>, ParentType, ContextType, RequireFields<QuerydailySwappedToArgs, 'id' | 'subgraphError'>>;
  dailySwappedTos?: Resolver<Array<ResolversTypes['DailySwappedTo']>, ParentType, ContextType, RequireFields<QuerydailySwappedTosArgs, 'skip' | 'first' | 'subgraphError'>>;
  weeklySwappedTo?: Resolver<Maybe<ResolversTypes['WeeklySwappedTo']>, ParentType, ContextType, RequireFields<QueryweeklySwappedToArgs, 'id' | 'subgraphError'>>;
  weeklySwappedTos?: Resolver<Array<ResolversTypes['WeeklySwappedTo']>, ParentType, ContextType, RequireFields<QueryweeklySwappedTosArgs, 'skip' | 'first' | 'subgraphError'>>;
  monthlySwappedTo?: Resolver<Maybe<ResolversTypes['MonthlySwappedTo']>, ParentType, ContextType, RequireFields<QuerymonthlySwappedToArgs, 'id' | 'subgraphError'>>;
  monthlySwappedTos?: Resolver<Array<ResolversTypes['MonthlySwappedTo']>, ParentType, ContextType, RequireFields<QuerymonthlySwappedTosArgs, 'skip' | 'first' | 'subgraphError'>>;
  dayAccount?: Resolver<Maybe<ResolversTypes['DayAccount']>, ParentType, ContextType, RequireFields<QuerydayAccountArgs, 'id' | 'subgraphError'>>;
  dayAccounts?: Resolver<Array<ResolversTypes['DayAccount']>, ParentType, ContextType, RequireFields<QuerydayAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  weekAccount?: Resolver<Maybe<ResolversTypes['WeekAccount']>, ParentType, ContextType, RequireFields<QueryweekAccountArgs, 'id' | 'subgraphError'>>;
  weekAccounts?: Resolver<Array<ResolversTypes['WeekAccount']>, ParentType, ContextType, RequireFields<QueryweekAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  monthAccount?: Resolver<Maybe<ResolversTypes['MonthAccount']>, ParentType, ContextType, RequireFields<QuerymonthAccountArgs, 'id' | 'subgraphError'>>;
  monthAccounts?: Resolver<Array<ResolversTypes['MonthAccount']>, ParentType, ContextType, RequireFields<QuerymonthAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  cashback?: Resolver<Maybe<ResolversTypes['Cashback']>, ParentType, ContextType, RequireFields<QuerycashbackArgs, 'id' | 'subgraphError'>>;
  cashbacks?: Resolver<Array<ResolversTypes['Cashback']>, ParentType, ContextType, RequireFields<QuerycashbacksArgs, 'skip' | 'first' | 'subgraphError'>>;
  referralFee?: Resolver<Maybe<ResolversTypes['ReferralFee']>, ParentType, ContextType, RequireFields<QueryreferralFeeArgs, 'id' | 'subgraphError'>>;
  referralFees?: Resolver<Array<ResolversTypes['ReferralFee']>, ParentType, ContextType, RequireFields<QueryreferralFeesArgs, 'skip' | 'first' | 'subgraphError'>>;
  claveTransaction?: Resolver<Maybe<ResolversTypes['ClaveTransaction']>, ParentType, ContextType, RequireFields<QueryclaveTransactionArgs, 'id' | 'subgraphError'>>;
  claveTransactions?: Resolver<Array<ResolversTypes['ClaveTransaction']>, ParentType, ContextType, RequireFields<QueryclaveTransactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  inAppSwap?: Resolver<Maybe<ResolversTypes['InAppSwap']>, ParentType, ContextType, RequireFields<QueryinAppSwapArgs, 'id' | 'subgraphError'>>;
  inAppSwaps?: Resolver<Array<ResolversTypes['InAppSwap']>, ParentType, ContextType, RequireFields<QueryinAppSwapsArgs, 'skip' | 'first' | 'subgraphError'>>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  claveAccount?: SubscriptionResolver<Maybe<ResolversTypes['ClaveAccount']>, "claveAccount", ParentType, ContextType, RequireFields<SubscriptionclaveAccountArgs, 'id' | 'subgraphError'>>;
  claveAccounts?: SubscriptionResolver<Array<ResolversTypes['ClaveAccount']>, "claveAccounts", ParentType, ContextType, RequireFields<SubscriptionclaveAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  erc20?: SubscriptionResolver<Maybe<ResolversTypes['ERC20']>, "erc20", ParentType, ContextType, RequireFields<Subscriptionerc20Args, 'id' | 'subgraphError'>>;
  erc20S?: SubscriptionResolver<Array<ResolversTypes['ERC20']>, "erc20S", ParentType, ContextType, RequireFields<Subscriptionerc20SArgs, 'skip' | 'first' | 'subgraphError'>>;
  erc20Balance?: SubscriptionResolver<Maybe<ResolversTypes['ERC20Balance']>, "erc20Balance", ParentType, ContextType, RequireFields<Subscriptionerc20BalanceArgs, 'id' | 'subgraphError'>>;
  erc20Balances?: SubscriptionResolver<Array<ResolversTypes['ERC20Balance']>, "erc20Balances", ParentType, ContextType, RequireFields<Subscriptionerc20BalancesArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_metaArgs>>;
  total?: SubscriptionResolver<Maybe<ResolversTypes['Total']>, "total", ParentType, ContextType, RequireFields<SubscriptiontotalArgs, 'id' | 'subgraphError'>>;
  totals?: SubscriptionResolver<Array<ResolversTypes['Total']>, "totals", ParentType, ContextType, RequireFields<SubscriptiontotalsArgs, 'skip' | 'first' | 'subgraphError'>>;
  day?: SubscriptionResolver<Maybe<ResolversTypes['Day']>, "day", ParentType, ContextType, RequireFields<SubscriptiondayArgs, 'id' | 'subgraphError'>>;
  days?: SubscriptionResolver<Array<ResolversTypes['Day']>, "days", ParentType, ContextType, RequireFields<SubscriptiondaysArgs, 'skip' | 'first' | 'subgraphError'>>;
  week?: SubscriptionResolver<Maybe<ResolversTypes['Week']>, "week", ParentType, ContextType, RequireFields<SubscriptionweekArgs, 'id' | 'subgraphError'>>;
  weeks?: SubscriptionResolver<Array<ResolversTypes['Week']>, "weeks", ParentType, ContextType, RequireFields<SubscriptionweeksArgs, 'skip' | 'first' | 'subgraphError'>>;
  month?: SubscriptionResolver<Maybe<ResolversTypes['Month']>, "month", ParentType, ContextType, RequireFields<SubscriptionmonthArgs, 'id' | 'subgraphError'>>;
  months?: SubscriptionResolver<Array<ResolversTypes['Month']>, "months", ParentType, ContextType, RequireFields<SubscriptionmonthsArgs, 'skip' | 'first' | 'subgraphError'>>;
  earnPosition?: SubscriptionResolver<Maybe<ResolversTypes['EarnPosition']>, "earnPosition", ParentType, ContextType, RequireFields<SubscriptionearnPositionArgs, 'id' | 'subgraphError'>>;
  earnPositions?: SubscriptionResolver<Array<ResolversTypes['EarnPosition']>, "earnPositions", ParentType, ContextType, RequireFields<SubscriptionearnPositionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  dailyEarnFlow?: SubscriptionResolver<Maybe<ResolversTypes['DailyEarnFlow']>, "dailyEarnFlow", ParentType, ContextType, RequireFields<SubscriptiondailyEarnFlowArgs, 'id' | 'subgraphError'>>;
  dailyEarnFlows?: SubscriptionResolver<Array<ResolversTypes['DailyEarnFlow']>, "dailyEarnFlows", ParentType, ContextType, RequireFields<SubscriptiondailyEarnFlowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  weeklyEarnFlow?: SubscriptionResolver<Maybe<ResolversTypes['WeeklyEarnFlow']>, "weeklyEarnFlow", ParentType, ContextType, RequireFields<SubscriptionweeklyEarnFlowArgs, 'id' | 'subgraphError'>>;
  weeklyEarnFlows?: SubscriptionResolver<Array<ResolversTypes['WeeklyEarnFlow']>, "weeklyEarnFlows", ParentType, ContextType, RequireFields<SubscriptionweeklyEarnFlowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  monthlyEarnFlow?: SubscriptionResolver<Maybe<ResolversTypes['MonthlyEarnFlow']>, "monthlyEarnFlow", ParentType, ContextType, RequireFields<SubscriptionmonthlyEarnFlowArgs, 'id' | 'subgraphError'>>;
  monthlyEarnFlows?: SubscriptionResolver<Array<ResolversTypes['MonthlyEarnFlow']>, "monthlyEarnFlows", ParentType, ContextType, RequireFields<SubscriptionmonthlyEarnFlowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  dailySwappedTo?: SubscriptionResolver<Maybe<ResolversTypes['DailySwappedTo']>, "dailySwappedTo", ParentType, ContextType, RequireFields<SubscriptiondailySwappedToArgs, 'id' | 'subgraphError'>>;
  dailySwappedTos?: SubscriptionResolver<Array<ResolversTypes['DailySwappedTo']>, "dailySwappedTos", ParentType, ContextType, RequireFields<SubscriptiondailySwappedTosArgs, 'skip' | 'first' | 'subgraphError'>>;
  weeklySwappedTo?: SubscriptionResolver<Maybe<ResolversTypes['WeeklySwappedTo']>, "weeklySwappedTo", ParentType, ContextType, RequireFields<SubscriptionweeklySwappedToArgs, 'id' | 'subgraphError'>>;
  weeklySwappedTos?: SubscriptionResolver<Array<ResolversTypes['WeeklySwappedTo']>, "weeklySwappedTos", ParentType, ContextType, RequireFields<SubscriptionweeklySwappedTosArgs, 'skip' | 'first' | 'subgraphError'>>;
  monthlySwappedTo?: SubscriptionResolver<Maybe<ResolversTypes['MonthlySwappedTo']>, "monthlySwappedTo", ParentType, ContextType, RequireFields<SubscriptionmonthlySwappedToArgs, 'id' | 'subgraphError'>>;
  monthlySwappedTos?: SubscriptionResolver<Array<ResolversTypes['MonthlySwappedTo']>, "monthlySwappedTos", ParentType, ContextType, RequireFields<SubscriptionmonthlySwappedTosArgs, 'skip' | 'first' | 'subgraphError'>>;
  dayAccount?: SubscriptionResolver<Maybe<ResolversTypes['DayAccount']>, "dayAccount", ParentType, ContextType, RequireFields<SubscriptiondayAccountArgs, 'id' | 'subgraphError'>>;
  dayAccounts?: SubscriptionResolver<Array<ResolversTypes['DayAccount']>, "dayAccounts", ParentType, ContextType, RequireFields<SubscriptiondayAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  weekAccount?: SubscriptionResolver<Maybe<ResolversTypes['WeekAccount']>, "weekAccount", ParentType, ContextType, RequireFields<SubscriptionweekAccountArgs, 'id' | 'subgraphError'>>;
  weekAccounts?: SubscriptionResolver<Array<ResolversTypes['WeekAccount']>, "weekAccounts", ParentType, ContextType, RequireFields<SubscriptionweekAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  monthAccount?: SubscriptionResolver<Maybe<ResolversTypes['MonthAccount']>, "monthAccount", ParentType, ContextType, RequireFields<SubscriptionmonthAccountArgs, 'id' | 'subgraphError'>>;
  monthAccounts?: SubscriptionResolver<Array<ResolversTypes['MonthAccount']>, "monthAccounts", ParentType, ContextType, RequireFields<SubscriptionmonthAccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  cashback?: SubscriptionResolver<Maybe<ResolversTypes['Cashback']>, "cashback", ParentType, ContextType, RequireFields<SubscriptioncashbackArgs, 'id' | 'subgraphError'>>;
  cashbacks?: SubscriptionResolver<Array<ResolversTypes['Cashback']>, "cashbacks", ParentType, ContextType, RequireFields<SubscriptioncashbacksArgs, 'skip' | 'first' | 'subgraphError'>>;
  referralFee?: SubscriptionResolver<Maybe<ResolversTypes['ReferralFee']>, "referralFee", ParentType, ContextType, RequireFields<SubscriptionreferralFeeArgs, 'id' | 'subgraphError'>>;
  referralFees?: SubscriptionResolver<Array<ResolversTypes['ReferralFee']>, "referralFees", ParentType, ContextType, RequireFields<SubscriptionreferralFeesArgs, 'skip' | 'first' | 'subgraphError'>>;
  claveTransaction?: SubscriptionResolver<Maybe<ResolversTypes['ClaveTransaction']>, "claveTransaction", ParentType, ContextType, RequireFields<SubscriptionclaveTransactionArgs, 'id' | 'subgraphError'>>;
  claveTransactions?: SubscriptionResolver<Array<ResolversTypes['ClaveTransaction']>, "claveTransactions", ParentType, ContextType, RequireFields<SubscriptionclaveTransactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  inAppSwap?: SubscriptionResolver<Maybe<ResolversTypes['InAppSwap']>, "inAppSwap", ParentType, ContextType, RequireFields<SubscriptioninAppSwapArgs, 'id' | 'subgraphError'>>;
  inAppSwaps?: SubscriptionResolver<Array<ResolversTypes['InAppSwap']>, "inAppSwaps", ParentType, ContextType, RequireFields<SubscriptioninAppSwapsArgs, 'skip' | 'first' | 'subgraphError'>>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type ClaveAccountResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ClaveAccount'] = ResolversParentTypes['ClaveAccount']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  erc20balances?: Resolver<Array<ResolversTypes['ERC20Balance']>, ParentType, ContextType, RequireFields<ClaveAccounterc20balancesArgs, 'skip' | 'first'>>;
  creationDate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  deployDate?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  recoveryCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasRecovery?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRecovering?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  txCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  implementation?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  earnPositions?: Resolver<Array<ResolversTypes['EarnPosition']>, ParentType, ContextType, RequireFields<ClaveAccountearnPositionsArgs, 'skip' | 'first'>>;
  transactions?: Resolver<Array<ResolversTypes['ClaveTransaction']>, ParentType, ContextType, RequireFields<ClaveAccounttransactionsArgs, 'skip' | 'first'>>;
  inAppSwaps?: Resolver<Array<ResolversTypes['InAppSwap']>, ParentType, ContextType, RequireFields<ClaveAccountinAppSwapsArgs, 'skip' | 'first'>>;
  activeDays?: Resolver<Array<ResolversTypes['DayAccount']>, ParentType, ContextType, RequireFields<ClaveAccountactiveDaysArgs, 'skip' | 'first'>>;
  activeWeeks?: Resolver<Array<ResolversTypes['WeekAccount']>, ParentType, ContextType, RequireFields<ClaveAccountactiveWeeksArgs, 'skip' | 'first'>>;
  activeMonths?: Resolver<Array<ResolversTypes['MonthAccount']>, ParentType, ContextType, RequireFields<ClaveAccountactiveMonthsArgs, 'skip' | 'first'>>;
  cashbacks?: Resolver<Array<ResolversTypes['Cashback']>, ParentType, ContextType, RequireFields<ClaveAccountcashbacksArgs, 'skip' | 'first'>>;
  referralFees?: Resolver<Array<ResolversTypes['ReferralFee']>, ParentType, ContextType, RequireFields<ClaveAccountreferralFeesArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ERC20Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ERC20'] = ResolversParentTypes['ERC20']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ERC20BalanceResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ERC20Balance'] = ResolversParentTypes['ERC20Balance']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['ERC20'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Int8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int8'], any> {
  name: 'Int8';
}

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export type _Block_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentHash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CashbackResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Cashback'] = ResolversParentTypes['Cashback']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClaveTransactionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ClaveTransaction'] = ResolversParentTypes['ClaveTransaction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  gasCost?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  paymaster?: Resolver<ResolversTypes['Paymaster'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DailyEarnFlowResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['DailyEarnFlow'] = ResolversParentTypes['DailyEarnFlow']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['Day'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  protocol?: Resolver<ResolversTypes['EarnProtocol'], ParentType, ContextType>;
  amountIn?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountOut?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  claimedGain?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DailySwappedToResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['DailySwappedTo'] = ResolversParentTypes['DailySwappedTo']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['Day'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DayResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Day'] = ResolversParentTypes['Day']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  createdAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deployedAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasSponsored?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  investFlow?: Resolver<Array<ResolversTypes['DailyEarnFlow']>, ParentType, ContextType, RequireFields<DayinvestFlowArgs, 'skip' | 'first'>>;
  swappedTo?: Resolver<Array<ResolversTypes['DailySwappedTo']>, ParentType, ContextType, RequireFields<DayswappedToArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DayAccountResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['DayAccount'] = ResolversParentTypes['DayAccount']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['Day'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EarnPositionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['EarnPosition'] = ResolversParentTypes['EarnPosition']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  pool?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  protocol?: Resolver<ResolversTypes['EarnProtocol'], ParentType, ContextType>;
  invested?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  compoundGain?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  normalGain?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InAppSwapResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['InAppSwap'] = ResolversParentTypes['InAppSwap']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  tokenIn?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amountIn?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  tokenOut?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amountOut?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MonthResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Month'] = ResolversParentTypes['Month']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  createdAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deployedAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasSponsored?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  investFlow?: Resolver<Array<ResolversTypes['MonthlyEarnFlow']>, ParentType, ContextType, RequireFields<MonthinvestFlowArgs, 'skip' | 'first'>>;
  swappedTo?: Resolver<Array<ResolversTypes['MonthlySwappedTo']>, ParentType, ContextType, RequireFields<MonthswappedToArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MonthAccountResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MonthAccount'] = ResolversParentTypes['MonthAccount']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['Month'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MonthlyEarnFlowResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MonthlyEarnFlow'] = ResolversParentTypes['MonthlyEarnFlow']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['Month'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  protocol?: Resolver<ResolversTypes['EarnProtocol'], ParentType, ContextType>;
  amountIn?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountOut?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  claimedGain?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MonthlySwappedToResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MonthlySwappedTo'] = ResolversParentTypes['MonthlySwappedTo']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['Month'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReferralFeeResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ReferralFee'] = ResolversParentTypes['ReferralFee']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  referred?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TotalResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Total'] = ResolversParentTypes['Total']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  createdAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deployedAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  backedUp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasSponsored?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeekResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Week'] = ResolversParentTypes['Week']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  createdAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deployedAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeAccounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasSponsored?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  investFlow?: Resolver<Array<ResolversTypes['WeeklyEarnFlow']>, ParentType, ContextType, RequireFields<WeekinvestFlowArgs, 'skip' | 'first'>>;
  swappedTo?: Resolver<Array<ResolversTypes['WeeklySwappedTo']>, ParentType, ContextType, RequireFields<WeekswappedToArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeekAccountResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['WeekAccount'] = ResolversParentTypes['WeekAccount']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['ClaveAccount'], ParentType, ContextType>;
  week?: Resolver<ResolversTypes['Week'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklyEarnFlowResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['WeeklyEarnFlow'] = ResolversParentTypes['WeeklyEarnFlow']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  week?: Resolver<ResolversTypes['Week'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  protocol?: Resolver<ResolversTypes['EarnProtocol'], ParentType, ContextType>;
  amountIn?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountOut?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  claimedGain?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WeeklySwappedToResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['WeeklySwappedTo'] = ResolversParentTypes['WeeklySwappedTo']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  week?: Resolver<ResolversTypes['Week'], ParentType, ContextType>;
  erc20?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  Query?: QueryResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  Bytes?: GraphQLScalarType;
  ClaveAccount?: ClaveAccountResolvers<ContextType>;
  ERC20?: ERC20Resolvers<ContextType>;
  ERC20Balance?: ERC20BalanceResolvers<ContextType>;
  Int8?: GraphQLScalarType;
  Timestamp?: GraphQLScalarType;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
  Cashback?: CashbackResolvers<ContextType>;
  ClaveTransaction?: ClaveTransactionResolvers<ContextType>;
  DailyEarnFlow?: DailyEarnFlowResolvers<ContextType>;
  DailySwappedTo?: DailySwappedToResolvers<ContextType>;
  Day?: DayResolvers<ContextType>;
  DayAccount?: DayAccountResolvers<ContextType>;
  EarnPosition?: EarnPositionResolvers<ContextType>;
  InAppSwap?: InAppSwapResolvers<ContextType>;
  Month?: MonthResolvers<ContextType>;
  MonthAccount?: MonthAccountResolvers<ContextType>;
  MonthlyEarnFlow?: MonthlyEarnFlowResolvers<ContextType>;
  MonthlySwappedTo?: MonthlySwappedToResolvers<ContextType>;
  ReferralFee?: ReferralFeeResolvers<ContextType>;
  Total?: TotalResolvers<ContextType>;
  Week?: WeekResolvers<ContextType>;
  WeekAccount?: WeekAccountResolvers<ContextType>;
  WeeklyEarnFlow?: WeeklyEarnFlowResolvers<ContextType>;
  WeeklySwappedTo?: WeeklySwappedToResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>;
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = ClaveBalancesTypes.Context & ClaveTypes.Context & BaseMeshContext;


import { fileURLToPath } from '@graphql-mesh/utils';
const baseDir = pathModule.join(pathModule.dirname(fileURLToPath(import.meta.url)), '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/clave-balances/introspectionSchema":
      return Promise.resolve(importedModule$0) as T;
    
    case ".graphclient/sources/clave/introspectionSchema":
      return Promise.resolve(importedModule$1) as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const claveBalancesTransforms = [];
const claveTransforms = [];
const additionalTypeDefs = [] as any[];
const claveBalancesHandler = new GraphqlHandler({
              name: "clave-balances",
              config: {"endpoint":"https://api.studio.thegraph.com/query/68557/clave-balances/version/latest"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("clave-balances"),
              logger: logger.child("clave-balances"),
              importFn,
            });
const claveHandler = new GraphqlHandler({
              name: "clave",
              config: {"endpoint":"https://gateway-arbitrum.network.thegraph.com/api/f1a21c41eacde9cd3df99c25564d9a93/subgraphs/id/7JwxT3cEzAMyimELqYsnprJMFBqDjBaaEa9Mk39e7Fsk"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("clave"),
              logger: logger.child("clave"),
              importFn,
            });
claveBalancesTransforms[0] = new AutoPaginationTransform({
                  apiName: "clave-balances",
                  config: {"validateSchema":true},
                  baseDir,
                  cache,
                  pubsub,
                  importFn,
                  logger,
                });
claveTransforms[0] = new AutoPaginationTransform({
                  apiName: "clave",
                  config: {"validateSchema":true},
                  baseDir,
                  cache,
                  pubsub,
                  importFn,
                  logger,
                });
sources[0] = {
          name: 'clave-balances',
          handler: claveBalancesHandler,
          transforms: claveBalancesTransforms
        }
sources[1] = {
          name: 'clave',
          handler: claveHandler,
          transforms: claveTransforms
        }
const additionalResolvers = [] as any[]
const merger = new(StitchingMerger as any)({
        cache,
        pubsub,
        logger: logger.child('stitchingMerger'),
        store: rootStore.child('stitchingMerger')
      })

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: StatsDocument,
        get rawSDL() {
          return printWithCache(StatsDocument);
        },
        location: 'StatsDocument.graphql'
      },{
        document: AllSwapsDocument,
        get rawSDL() {
          return printWithCache(AllSwapsDocument);
        },
        location: 'AllSwapsDocument.graphql'
      },{
        document: AllUsersStatsDocument,
        get rawSDL() {
          return printWithCache(AllUsersStatsDocument);
        },
        location: 'AllUsersStatsDocument.graphql'
      },{
        document: BalancesDocument,
        get rawSDL() {
          return printWithCache(BalancesDocument);
        },
        location: 'BalancesDocument.graphql'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler<TServerContext = {}>(): MeshHTTPHandler<TServerContext> {
  return createMeshHTTPHandler<TServerContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type StatsQueryVariables = Exact<{ [key: string]: never; }>;


export type StatsQuery = { days: Array<(
    Pick<Day, 'id' | 'createdAccounts' | 'deployedAccounts' | 'activeAccounts' | 'transactions' | 'gasSponsored'>
    & { swappedTo: Array<Pick<DailySwappedTo, 'erc20' | 'amount'>>, investFlow: Array<Pick<DailyEarnFlow, 'erc20' | 'protocol' | 'amountIn' | 'amountOut' | 'claimedGain'>> }
  )>, weeks: Array<(
    Pick<Week, 'id' | 'createdAccounts' | 'deployedAccounts' | 'activeAccounts' | 'transactions' | 'gasSponsored'>
    & { swappedTo: Array<Pick<WeeklySwappedTo, 'erc20' | 'amount'>>, investFlow: Array<Pick<WeeklyEarnFlow, 'erc20' | 'protocol' | 'amountIn' | 'amountOut' | 'claimedGain'>> }
  )>, months: Array<(
    Pick<Month, 'id' | 'activeAccounts' | 'createdAccounts' | 'deployedAccounts' | 'transactions' | 'gasSponsored'>
    & { swappedTo: Array<Pick<MonthlySwappedTo, 'erc20' | 'amount'>>, investFlow: Array<Pick<MonthlyEarnFlow, 'erc20' | 'protocol' | 'amountIn' | 'amountOut' | 'claimedGain'>> }
  )>, total?: Maybe<Pick<Total, 'createdAccounts' | 'deployedAccounts' | 'gasSponsored' | 'transactions' | 'backedUp'>> };

export type AllSwapsQueryVariables = Exact<{
  lastID?: InputMaybe<Scalars['Bytes']>;
}>;


export type AllSwapsQuery = { inAppSwaps: Array<(
    Pick<InAppSwap, 'id' | 'amountOut' | 'tokenOut'>
    & { account: Pick<ClaveAccount, 'id'> }
  )> };

export type AllUsersStatsQueryVariables = Exact<{
  lastID?: InputMaybe<Scalars['Bytes']>;
}>;


export type AllUsersStatsQuery = { claveAccounts: Array<(
    Pick<ClaveAccount, 'id' | 'creationDate' | 'deployDate' | 'hasRecovery' | 'txCount'>
    & { activeDays: Array<Pick<DayAccount, 'id'>>, activeWeeks: Array<Pick<WeekAccount, 'id'>>, activeMonths: Array<Pick<MonthAccount, 'id'>>, earnPositions: Array<Pick<EarnPosition, 'pool' | 'token' | 'protocol' | 'invested' | 'compoundGain' | 'normalGain'>>, referralFees: Array<Pick<ReferralFee, 'erc20' | 'amount'>>, cashbacks: Array<Pick<Cashback, 'erc20' | 'amount'>> }
  )> };

export type BalancesQueryVariables = Exact<{
  lastID?: InputMaybe<Scalars['Bytes']>;
}>;


export type BalancesQuery = { erc20Balances: Array<(
    Pick<ERC20Balance, 'id' | 'amount'>
    & { account: Pick<ClaveAccount, 'id'>, token: Pick<ERC20, 'id'> }
  )> };


export const StatsDocument = gql`
    query Stats {
  days(first: 1000) {
    id
    createdAccounts
    deployedAccounts
    activeAccounts
    transactions
    gasSponsored
    swappedTo {
      erc20
      amount
    }
    investFlow {
      erc20
      protocol
      amountIn
      amountOut
      claimedGain
    }
  }
  weeks {
    id
    createdAccounts
    deployedAccounts
    activeAccounts
    transactions
    gasSponsored
    swappedTo {
      erc20
      amount
    }
    investFlow {
      erc20
      protocol
      amountIn
      amountOut
      claimedGain
    }
  }
  months {
    id
    activeAccounts
    createdAccounts
    deployedAccounts
    transactions
    gasSponsored
    swappedTo {
      erc20
      amount
    }
    investFlow {
      erc20
      protocol
      amountIn
      amountOut
      claimedGain
    }
  }
  total(id: "0x746f74616c") {
    createdAccounts
    deployedAccounts
    gasSponsored
    transactions
    backedUp
  }
}
    ` as unknown as DocumentNode<StatsQuery, StatsQueryVariables>;
export const AllSwapsDocument = gql`
    query AllSwaps($lastID: Bytes) {
  inAppSwaps(first: 6000, where: {id_gt: $lastID}) {
    id
    account {
      id
    }
    amountOut
    tokenOut
  }
}
    ` as unknown as DocumentNode<AllSwapsQuery, AllSwapsQueryVariables>;
export const AllUsersStatsDocument = gql`
    query AllUsersStats($lastID: Bytes) {
  claveAccounts(first: 6000, where: {id_gt: $lastID}) {
    id
    creationDate
    deployDate
    hasRecovery
    txCount
    activeDays(first: 500) {
      id
    }
    activeWeeks {
      id
    }
    activeMonths {
      id
    }
    earnPositions {
      pool
      token
      protocol
      invested
      compoundGain
      normalGain
    }
    referralFees {
      erc20
      amount
    }
    cashbacks {
      erc20
      amount
    }
  }
}
    ` as unknown as DocumentNode<AllUsersStatsQuery, AllUsersStatsQueryVariables>;
export const BalancesDocument = gql`
    query Balances($lastID: Bytes) {
  erc20Balances(first: 6000, where: {id_gt: $lastID}) {
    id
    account {
      id
    }
    token {
      id
    }
    amount
  }
}
    ` as unknown as DocumentNode<BalancesQuery, BalancesQueryVariables>;





export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    Stats(variables?: StatsQueryVariables, options?: C): Promise<StatsQuery> {
      return requester<StatsQuery, StatsQueryVariables>(StatsDocument, variables, options) as Promise<StatsQuery>;
    },
    AllSwaps(variables?: AllSwapsQueryVariables, options?: C): Promise<AllSwapsQuery> {
      return requester<AllSwapsQuery, AllSwapsQueryVariables>(AllSwapsDocument, variables, options) as Promise<AllSwapsQuery>;
    },
    AllUsersStats(variables?: AllUsersStatsQueryVariables, options?: C): Promise<AllUsersStatsQuery> {
      return requester<AllUsersStatsQuery, AllUsersStatsQueryVariables>(AllUsersStatsDocument, variables, options) as Promise<AllUsersStatsQuery>;
    },
    Balances(variables?: BalancesQueryVariables, options?: C): Promise<BalancesQuery> {
      return requester<BalancesQuery, BalancesQueryVariables>(BalancesDocument, variables, options) as Promise<BalancesQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;