/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
    DollarCircleTwoTone,
    DownloadOutlined,
    PlusOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Dropdown,
    Modal,
    Row,
    Select,
    Space,
    Spin,
    Statistic,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { Input } from 'antd';
import {
    useAllSwapsQuery,
    useAllUsersStatsQuery,
    useGetAllStatsQuery,
    useIsUpgradedQuery,
} from 'api';
import { useScheduleNotificationForAddressesMutation } from 'api/mutations/useScheduleNotificationMutation';
import { AllStatsFilters } from 'components/dashboardV2/AllStatsFilters';
import { AllStatsSavedFilters } from 'components/dashboardV2/AllStatsSavedFilters';
import { AllStatsSelectColumns } from 'components/dashboardV2/AllStatsSelectColumns';
import { useModalController } from 'hooks/useModalController';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import type { $MixedElement, AllStatsFilter, AllStatsSavedFilter } from 'types';
import {
    Permission,
    Storage,
    StorageKeys,
    defaultUserData,
    permissions,
} from 'utils';
import {
    DEFAULT_CHECKED,
    ETH_ADDRESS,
    FEE_PCT,
    GRAPH_ETH_ADDRESS,
    type IUser,
    PAGE_SIZE,
    type UserBalances,
    UserColumns,
    addressToToken,
    formatUnits,
    fromDollar,
    toDollar,
    tokenAddressToDecimal,
} from 'utils';

export const AllStats = ({
    tokenPrices,
    balancesUSD,
    balances,
}: {
    tokenPrices: Record<string, number> | null;
    balancesUSD: UserBalances | null;
    balances: UserBalances | null;
}): $MixedElement => {
    //state
    const [checkedList, setCheckedList] =
        useState<Array<keyof IUser>>(DEFAULT_CHECKED);
    const [config, setConfig] = useState({
        sortBy: 'totalPortfolio' as keyof IUser,
        filters: [] as Array<AllStatsFilter>,
        filterCondition: 'AND' as 'OR' | 'AND',
        groupBy: 'None' as 'None' | 'Country',
    });
    const [sortBy, setSortBy] = useState<keyof IUser>('totalPortfolio');
    const [filters, setFilters] = useState<Array<AllStatsFilter>>([]);
    const [filterCondition, setFilterCondition] = useState<'OR' | 'AND'>('AND');
    const [groupBy, setGroupBy] = useState<'None' | 'Country'>('None');
    const [notifTitle, setNotifTitle] = useState('');
    const [notifMessage, setNotifMessage] = useState('');
    const [notifDeeplink, setNotifDeeplink] = useState('');
    const [filterName, setFilterName] = useState('');
    const [savedFilters, setSavedFilters] = useState<
        Array<AllStatsSavedFilter>
    >([]);

    const trimmedNotifMessage = notifMessage.trim();
    const trimmedNotifTitle = notifTitle.trim();

    //helpers
    const applyButtonRef = useRef<HTMLButtonElement | null>(null);
    const scheduleModal = useModalController();
    const saveFiltersModal = useModalController();
    const scheduleNotificationMutation =
        useScheduleNotificationForAddressesMutation();

    const getExportableData = (data: Array<IUser> | null): Array<object> => {
        if (!data) {
            return [];
        }

        return data.map((user) => {
            const result: { [key: string]: string | number | JSX.Element } = {};
            for (const key of checkedList) {
                result[key == 'key' ? 'address' : key] =
                    user[key as keyof IUser];
                if (key === 'earnPositions' || key === 'realizedGain') {
                    result[key] = toDollar(
                        parseFloat((result[key] as JSX.Element).key ?? '0'),
                    );
                } else if (key === 'txCount') {
                    result[key] = Number((result[key] as JSX.Element).key);
                }
            }
            return result;
        });
    };

    //queries
    const { data: stats } = useAllUsersStatsQuery();
    const {
        data: { inAppSwaps: swaps },
    } = useAllSwapsQuery();
    const { data: statsDb } = useGetAllStatsQuery();
    const { data: isUpgraded, refetch: refetchIsUpgraded } = useIsUpgradedQuery(
        stats.claveAccounts
            .filter((account) => account.deployDate != null)
            .map((account) => account.id),
    );

    useEffect(() => {
        if (stats.claveAccounts.length > 15100) {
            refetchIsUpgraded();
        }
    }, [stats]);

    //memoizations
    const revenueGenerated = useMemo(() => {
        const revenueGenerated: Record<string, number> = {};
        if (!swaps || !tokenPrices) {
            return null;
        }
        swaps.forEach((swap) => {
            const tokenAddress = swap.tokenOut.toLowerCase();
            const token = addressToToken[tokenAddress];
            if (!token) {
                return;
            }
            const decimals = token.decimals;
            const tokenPrice = tokenPrices[tokenAddress];
            if (!tokenPrice) {
                return;
            }
            const amountOut = formatUnits(swap.amountOut, decimals);
            const fee = amountOut * FEE_PCT;
            const usdValue = fee * tokenPrice;
            revenueGenerated[swap.account.id] =
                (revenueGenerated[swap.account.id] ?? 0) + usdValue;
        });

        return revenueGenerated;
    }, [swaps, tokenPrices]);

    const columns = useMemo(() => {
        return UserColumns.map((column) => {
            if (column.title === config.sortBy) {
                return {
                    ...column,
                    hidden: !checkedList.includes(column.key as keyof IUser),
                };
            } else {
                return {
                    ...column,
                    hidden: !checkedList.includes(column.key as keyof IUser),
                };
            }
        });
    }, [config, checkedList]);

    const tableData = useMemo(() => {
        if (
            !stats ||
            !balancesUSD ||
            !revenueGenerated ||
            !tokenPrices ||
            stats.claveAccounts.length < 15100
        ) {
            return null;
        }

        const tableData: Array<IUser> = stats.claveAccounts.map((account) => {
            const address: string = account.id;
            const revenue = revenueGenerated[address] ?? 0;
            const upgraded = isUpgraded[address] == false ? 'false' : 'true';
            const statDb = statsDb[address] ?? defaultUserData;
            const countryCode = statDb.countryCode ?? 'N/A';
            const creationDate = statDb.creationDate.slice(4);
            let onramped = 0;

            const onrampsForAccount = statDb.onrampData ?? {};
            const onrampData = Object.entries(onrampsForAccount).reduce(
                (acc, [address, amount]) => {
                    const tokenAddress =
                        address === GRAPH_ETH_ADDRESS ? ETH_ADDRESS : address;
                    const token = addressToToken[tokenAddress];
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const decimal = tokenAddressToDecimal[tokenAddress] ?? 18;
                    const usdValue = formatUnits(amount, decimal) * tokenPrice;
                    onramped += usdValue;
                    acc.push({
                        token: token?.symbol ?? tokenAddress,
                        deposited: usdValue,
                    });
                    return acc;
                },
                [] as Array<{ token: string; deposited: number }>,
            );

            const campaignStatus =
                statDb.campaigns.find((campaign) => campaign.id == 2)
                    ?.status === 'done'
                    ? 'done'
                    : onramped > 14
                    ? 'pending'
                    : 'N/A';

            const totalBalance = Object.entries(
                balancesUSD[account.id] ?? {},
            ).reduce((acc, [, balance]) => acc + balance, 0);

            const activeDays = account.activeDays.length;
            const activeWeeks = account.activeWeeks.length;
            const activeMonths = account.activeMonths.length;

            const refCount = statDb.referredWallets.length ?? 0;

            let deposited = 0;

            const depositsForAccount = statDb.depositData ?? {};
            const depositData = Object.entries(depositsForAccount).reduce(
                (acc, [address, amount]) => {
                    const tokenAddress =
                        address === GRAPH_ETH_ADDRESS ? ETH_ADDRESS : address;
                    const token = addressToToken[tokenAddress];
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const decimal = tokenAddressToDecimal[tokenAddress] ?? 18;
                    const usdValue = formatUnits(amount, decimal) * tokenPrice;
                    deposited += usdValue;
                    acc.push({
                        token: token?.symbol ?? tokenAddress,
                        deposited: usdValue,
                    });
                    return acc;
                },
                [] as Array<{ token: string; deposited: number }>,
            );

            let invested = 0;

            const earnPositions = account.earnPositions.reduce(
                (acc, position) => {
                    let tokenAddress = position.token.toLowerCase();
                    if (tokenAddress === GRAPH_ETH_ADDRESS)
                        tokenAddress = ETH_ADDRESS;
                    const token = addressToToken[tokenAddress];
                    const amount = formatUnits(
                        position.invested,
                        token?.decimals ?? 18,
                    );
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const usdValue = amount * tokenPrice;
                    invested += usdValue;
                    acc.push({
                        protocol: `${position.protocol}${token?.symbol}`,
                        invested: usdValue,
                    });
                    return acc;
                },
                [] as Array<{ protocol: string; invested: number }>,
            );

            let realizedGain = 0;

            const earnGains = account.earnPositions.reduce((acc, position) => {
                let tokenAddress = position.token.toLowerCase();
                if (tokenAddress === GRAPH_ETH_ADDRESS)
                    tokenAddress = ETH_ADDRESS;
                const token = addressToToken[tokenAddress];
                const gain =
                    position.protocol === 'ZeroLend'
                        ? position.normalGain + position.compoundGain
                        : position.normalGain;
                const amount = formatUnits(gain, token?.decimals ?? 18);
                const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                const usdValue = amount * tokenPrice;
                realizedGain += usdValue;
                acc.push({
                    protocol: `${position.protocol}${token?.symbol}`,
                    gained: usdValue,
                });
                return acc;
            }, [] as Array<{ protocol: string; gained: number }>);

            const refFeeGains = account.referralFees.reduce((acc, fee) => {
                let tokenAddress = fee.erc20.toLowerCase();
                if (tokenAddress === GRAPH_ETH_ADDRESS)
                    tokenAddress = ETH_ADDRESS;
                const token = addressToToken[tokenAddress];
                const amount = formatUnits(fee.amount, token?.decimals ?? 18);
                const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                const usdValue = amount * tokenPrice;
                return acc + usdValue;
            }, 0);

            const cashbackGains = account.cashbacks.reduce((acc, cashback) => {
                let tokenAddress = cashback.erc20.toLowerCase();
                if (tokenAddress === GRAPH_ETH_ADDRESS)
                    tokenAddress = ETH_ADDRESS;
                const token = addressToToken[tokenAddress];
                const amount = formatUnits(
                    cashback.amount,
                    token?.decimals ?? 18,
                );
                const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                const usdValue = amount * tokenPrice;
                return acc + usdValue;
            }, 0);

            const username = statDb.username ?? 'N/A';
            const email = statDb.email ?? 'N/A';

            const dist = statDb.txDistribution;
            const earn = dist?.invest || 0;
            const paymentLinks = dist?.peanut || 0;
            const swap = dist?.swap || 0;
            const transfer = dist?.transfer || 0;

            return {
                username,
                key: address,
                device: statDb.device ?? 'N/A',
                countryCode,
                email,
                referrer:
                    statsDb[statDb.referrerAddress ?? '']?.username ?? 'N/A',
                totalPortfolio: toDollar(totalBalance + invested),
                idlePortfolio: toDollar(totalBalance),
                swapRevenue: toDollar(revenue),
                groups: statDb.groups,
                txCount: (
                    <Tooltip
                        title={
                            <>
                                <p>payment links: {paymentLinks}</p>
                                <p>swap: {swap}</p>
                                <p>transfer: {transfer}</p>
                                <p>earn: {earn}</p>
                            </>
                        }
                        key={account.txCount}
                    >
                        <span>{account.txCount}</span>
                    </Tooltip>
                ),
                earnPositions: (
                    <Tooltip
                        title={
                            <>
                                {earnPositions.map(
                                    (position) =>
                                        position.invested > 0.1 && (
                                            <p key={position.invested}>
                                                {position.protocol}:{' '}
                                                {toDollar(position.invested)}
                                            </p>
                                        ),
                                )}
                            </>
                        }
                        key={invested}
                    >
                        <span>{toDollar(invested)}</span>
                    </Tooltip>
                ),
                hasBackup: account.hasRecovery.toString(),
                campaignStatus,
                creationDate,
                activeDays,
                activeWeeks,
                activeMonths,
                realizedGain: (
                    <Tooltip
                        title={
                            <>
                                {earnGains.map(
                                    (gain) =>
                                        gain.gained > 0.1 && (
                                            <p key={gain.gained}>
                                                {gain.protocol}:{' '}
                                                {toDollar(gain.gained)}
                                            </p>
                                        ),
                                )}
                            </>
                        }
                        key={realizedGain}
                    >
                        <span>{toDollar(realizedGain)}</span>
                    </Tooltip>
                ),
                deposit: (
                    <Tooltip
                        title={
                            <>
                                {depositData.map((deposit) => (
                                    <p key={deposit.deposited}>
                                        {deposit.token}:{' '}
                                        {toDollar(deposit.deposited)}
                                    </p>
                                ))}
                            </>
                        }
                        key={deposited}
                    >
                        <span>{toDollar(deposited)}</span>
                    </Tooltip>
                ),
                onramp: (
                    <Tooltip
                        title={
                            <>
                                {onrampData.map((onramp) => (
                                    <p key={onramp.deposited}>
                                        {onramp.token}:{' '}
                                        {toDollar(onramp.deposited)}
                                    </p>
                                ))}
                            </>
                        }
                        key={onramped}
                    >
                        <span>{toDollar(onramped)}</span>
                    </Tooltip>
                ),
                referralGain: (
                    <Tooltip
                        title={
                            <>
                                <p>referral fee: {toDollar(refFeeGains)}</p>
                                <p>cashback: {toDollar(cashbackGains)}</p>
                            </>
                        }
                        key={refFeeGains + cashbackGains}
                    >
                        {toDollar(refFeeGains + cashbackGains)}
                    </Tooltip>
                ),
                refCount,
                earn,
                paymentLinks,
                swap,
                transfer,
                upgraded,
            };
        });

        const filteredTableData = tableData.filter((user) => {
            const method = config.filterCondition === 'OR' ? 'some' : 'every';

            return filters[method]((filter) => {
                const value = user[filter.column];
                switch (filter.operator) {
                    case 'eq':
                        switch (filter.column) {
                            case 'txCount':
                            case 'earnPositions':
                            case 'realizedGain':
                            case 'deposit':
                            case 'onramp':
                            case 'referralGain': {
                                return (
                                    (value as JSX.Element).key === filter.value
                                );
                            }
                            case 'totalPortfolio':
                            case 'idlePortfolio':
                            case 'swapRevenue': {
                                return (
                                    fromDollar(value as string) ===
                                    Number(filter.value)
                                );
                            }
                            case 'creationDate': {
                                return (
                                    new Date(value as string).getTime() ===
                                    new Date(filter.value as string).getTime()
                                );
                            }
                            default:
                                return value === filter.value;
                        }
                    case 'gt':
                        switch (filter.column) {
                            case 'txCount':
                            case 'earnPositions':
                            case 'realizedGain':
                            case 'deposit':
                            case 'onramp':
                            case 'referralGain': {
                                return (
                                    Number((value as JSX.Element).key) >
                                    Number(filter.value)
                                );
                            }
                            case 'totalPortfolio':
                            case 'idlePortfolio':
                            case 'swapRevenue': {
                                return (
                                    fromDollar(value as string) >
                                    Number(filter.value)
                                );
                            }
                            case 'creationDate': {
                                return (
                                    new Date(value as string).getTime() >
                                    new Date(filter.value as string).getTime()
                                );
                            }
                            default:
                                return Number(value) > Number(filter.value);
                        }
                    case 'lt':
                        switch (filter.column) {
                            case 'txCount':
                            case 'earnPositions':
                            case 'realizedGain':
                            case 'deposit':
                            case 'onramp':
                            case 'referralGain': {
                                return (
                                    Number((value as JSX.Element).key) <
                                    Number(filter.value)
                                );
                            }
                            case 'totalPortfolio':
                            case 'idlePortfolio':
                            case 'swapRevenue': {
                                return (
                                    fromDollar(value as string) <
                                    Number(filter.value)
                                );
                            }
                            case 'creationDate': {
                                return (
                                    new Date(value as string).getTime() <
                                    new Date(filter.value as string).getTime()
                                );
                            }
                            default:
                                return Number(value) < Number(filter.value);
                        }
                    case 'neq':
                        switch (filter.column) {
                            case 'txCount':
                            case 'earnPositions':
                            case 'realizedGain':
                            case 'deposit':
                            case 'onramp':
                            case 'referralGain': {
                                return (
                                    (value as JSX.Element).key !== filter.value
                                );
                            }
                            case 'totalPortfolio':
                            case 'idlePortfolio':
                            case 'swapRevenue': {
                                return (
                                    fromDollar(value as string) !==
                                    Number(filter.value)
                                );
                            }
                            case 'hasBackup':
                            case 'upgraded': {
                                return Boolean(value) !== Boolean(filter.value);
                            }
                            case 'creationDate': {
                                return (
                                    new Date(value as string).getTime() !==
                                    new Date(filter.value as string).getTime()
                                );
                            }
                            default:
                                return value !== filter.value;
                        }
                    case 'contains':
                        if (typeof value === 'string') {
                            return value
                                .toLowerCase()
                                .includes(
                                    (filter.value as string).toLowerCase(),
                                );
                        } else if (Array.isArray(value)) {
                            return value.some((v) =>
                                v
                                    .toLowerCase()
                                    .includes(
                                        (filter.value as string).toLowerCase(),
                                    ),
                            );
                        } else {
                            return false;
                        }
                    case 'notContains':
                        return !(value as string)
                            .toLowerCase()
                            .includes((filter.value as string).toLowerCase());
                    default:
                        return true;
                }
            });
        });

        const groupedTableData =
            config.groupBy === 'Country'
                ? _.groupBy(filteredTableData, 'countryCode')
                : { All: filteredTableData };

        // Create summary rows for each country group
        const summaryData = Object.entries(groupedTableData).map(
            ([countryCode, group]) => {
                const summaryRow: IUser = {
                    key: `summary-${countryCode}`,
                    countryCode: countryCode,
                    username: `${countryCode} (${group.length} users)`,
                    totalPortfolio: toDollar(
                        _.sumBy(group, (user) =>
                            fromDollar(user.totalPortfolio as string),
                        ),
                    ),
                    idlePortfolio: toDollar(
                        _.sumBy(group, (user) =>
                            fromDollar(user.idlePortfolio as string),
                        ),
                    ),
                    swapRevenue: toDollar(
                        _.sumBy(group, (user) =>
                            fromDollar(user.swapRevenue as string),
                        ),
                    ),
                    txCount: (
                        <span>
                            {_.sumBy(group, (user) =>
                                Number((user.txCount as JSX.Element).key),
                            )}
                        </span>
                    ),
                    email: '-',
                    device: '-',
                    referrer: '-',
                    upgraded: '-',
                    hasBackup: '-',
                    activeDays: _.sumBy(group, 'activeDays'),
                    activeWeeks: _.sumBy(group, 'activeWeeks'),
                    activeMonths: _.sumBy(group, 'activeMonths'),
                    refCount: _.sumBy(group, 'refCount'),
                    creationDate: '-',
                    campaignStatus: '-',
                    earnPositions: (
                        <span>
                            {toDollar(
                                _.sumBy(group, (user) =>
                                    Number(
                                        (user.earnPositions as JSX.Element).key,
                                    ),
                                ),
                            )}
                        </span>
                    ),
                    realizedGain: (
                        <span>
                            {toDollar(
                                _.sumBy(group, (user) =>
                                    Number(
                                        (user.realizedGain as JSX.Element).key,
                                    ),
                                ),
                            )}
                        </span>
                    ),
                    deposit: (
                        <span>
                            {toDollar(
                                _.sumBy(group, (user) =>
                                    Number((user.deposit as JSX.Element).key),
                                ),
                            )}
                        </span>
                    ),
                    onramp: (
                        <span>
                            {toDollar(
                                _.sumBy(group, (user) =>
                                    Number((user.onramp as JSX.Element).key),
                                ),
                            )}
                        </span>
                    ),
                    referralGain: (
                        <span>
                            {toDollar(
                                _.sumBy(group, (user) =>
                                    Number(
                                        (user.referralGain as JSX.Element).key,
                                    ),
                                ),
                            )}
                        </span>
                    ),
                    earn: _.sumBy(group, 'earn'),
                    paymentLinks: _.sumBy(group, 'paymentLinks'),
                    swap: _.sumBy(group, 'swap'),
                    transfer: _.sumBy(group, 'transfer'),
                };
                return summaryRow;
            },
        );

        const dataToSort =
            config.groupBy === 'Country' ? summaryData : filteredTableData;

        const sortedTableData = dataToSort.sort((a, b) => {
            switch (config.sortBy) {
                case 'swapRevenue':
                case 'idlePortfolio':
                case 'totalPortfolio':
                    return (
                        fromDollar(b[config.sortBy] as string) -
                        fromDollar(a[config.sortBy] as string)
                    );
                case 'txCount':
                case 'earnPositions':
                case 'realizedGain':
                case 'deposit':
                case 'onramp':
                case 'referralGain':
                    return (
                        Number((b[config.sortBy] as JSX.Element).key) -
                        Number((a[config.sortBy] as JSX.Element).key)
                    );
                case 'activeDays':
                case 'activeWeeks':
                case 'activeMonths':
                case 'refCount':
                case 'earn':
                case 'paymentLinks':
                case 'swap':
                case 'transfer':
                    return Number(b[config.sortBy]) - Number(a[config.sortBy]);
                case 'creationDate':
                    return (
                        new Date(b[config.sortBy]).getTime() -
                        new Date(a[config.sortBy]).getTime()
                    );
                case 'key':
                case 'username':
                case 'email':
                    return a[config.sortBy].localeCompare(b[config.sortBy]);
                default:
                    return 0;
            }
        });

        return sortedTableData;
    }, [
        stats,
        isUpgraded,
        config,
        revenueGenerated,
        statsDb,
        filterCondition,
        groupBy,
    ]);

    const totals = useMemo(() => {
        let totalIdleTvl = 0;
        let totalInvested = 0;
        let totalRealizedGain = 0;
        let totalDeposited = 0;
        let totalSwapRevenue = 0;
        let totalOnramped = 0;
        let totalReferralGain = 0;

        if (tableData) {
            tableData.forEach((user) => {
                totalInvested += Number(
                    (user.earnPositions as JSX.Element).key,
                );
                totalRealizedGain += Number(
                    (user.realizedGain as JSX.Element).key,
                );
                totalDeposited += Number((user.deposit as JSX.Element).key);
                totalSwapRevenue += fromDollar(user.swapRevenue as string);
                totalIdleTvl += fromDollar(user.idlePortfolio as string);
                totalOnramped += Number((user.onramp as JSX.Element).key);
                totalReferralGain += Number(
                    (user.referralGain as JSX.Element).key,
                );
            });
        }

        return {
            totalIdleTvl,
            totalInvested,
            totalRealizedGain,
            totalDeposited,
            totalSwapRevenue,
            totalOnramped,
            totalReferralGain,
            totalRows: tableData?.length || 0,
        };
    }, [tableData]);

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): void => {
            if (e.key === 'Enter') {
                applyButtonRef.current?.click();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, []);

    useEffect(() => {
        const savedFilters = Storage.getJson<Array<AllStatsSavedFilter>>(
            StorageKeys.ALL_STATS_FILTERS,
        );
        setSavedFilters(savedFilters ?? []);
    }, []);

    //component
    if (!tableData) {
        return (
            <Spin tip="Loading" size="large">
                <div className="p-12 bg-gray-100 rounded-lg shadow-md" />
            </Spin>
        );
    }

    return (
        <div className="max-h-[100vh] overflow-auto bg-white p-6 rounded-lg shadow-lg">
            <Row gutter={[16, 16]} className="mb-8">
                {[
                    {
                        title: 'Total TVL',
                        value: totals.totalIdleTvl + totals.totalInvested,
                        color: '#3f8600',
                    },
                    {
                        title: 'Idle TVL',
                        value: totals.totalIdleTvl,
                        color: '#1890ff',
                    },
                    {
                        title: 'Earn TVL',
                        value: totals.totalInvested,
                        color: '#722ed1',
                    },
                    {
                        title: 'Total Realized Gain',
                        value: totals.totalRealizedGain,
                        color: '#fa8c16',
                    },
                    {
                        title: 'Total Deposited',
                        value: totals.totalDeposited,
                        color: '#13c2c2',
                    },
                    {
                        title: 'Total Swap Revenue',
                        value: totals.totalSwapRevenue,
                        color: '#eb2f96',
                    },
                    {
                        title: 'Total Onramped',
                        value: totals.totalOnramped,
                        color: '#2ecc71',
                    },
                    {
                        title: 'Total Referral Gain',
                        value: totals.totalReferralGain,
                        color: '#9b59b6',
                    },
                ].map((stat, index) => (
                    <Col span={6} key={index}>
                        <Card
                            bordered={false}
                            hoverable
                            className="text-center h-full"
                        >
                            <Statistic
                                title={
                                    <Typography.Text
                                        strong
                                        className="text-base"
                                    >
                                        {stat.title}
                                    </Typography.Text>
                                }
                                value={stat.value}
                                precision={2}
                                valueStyle={{
                                    color: stat.color,
                                    fontSize: '20px',
                                }}
                                prefix={
                                    <DollarCircleTwoTone
                                        twoToneColor={stat.color}
                                    />
                                }
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
            <div className="mb-6 bg-gray-50 p-4 rounded-md">
                <Row gutter={[16, 16]} align="middle">
                    <Col span={6}>
                        <Typography.Text strong>Sort by:</Typography.Text>
                        <Select
                            defaultValue={'totalPortfolio' as keyof IUser}
                            style={{ width: '100%', marginTop: 4 }}
                            onChange={(value) =>
                                setSortBy(value as keyof IUser)
                            }
                            options={UserColumns.map(({ key, title }) => ({
                                value: key,
                                label: title,
                            }))}
                        />
                    </Col>
                    <Col span={6}>
                        <Typography.Text strong>Group by:</Typography.Text>
                        <Select
                            value={groupBy}
                            style={{ width: '100%', marginTop: 4 }}
                            onChange={(value) =>
                                setGroupBy(value as 'None' | 'Country')
                            }
                            options={[
                                { value: 'None', label: 'None' },
                                { value: 'Country', label: 'Country' },
                            ]}
                        />
                    </Col>
                    <AllStatsSelectColumns
                        checkedList={checkedList}
                        setCheckedList={setCheckedList}
                    />
                    <AllStatsFilters
                        filters={filters}
                        setFilters={setFilters}
                        setFilterCondition={setFilterCondition}
                        saveFiltersController={saveFiltersModal}
                    />
                </Row>
                <Row gutter={[16, 16]} align="middle" className="mt-6">
                    <Col span={12}>
                        <Button
                            ref={applyButtonRef}
                            type="primary"
                            icon={<SearchOutlined />}
                            onClick={() =>
                                setConfig({
                                    sortBy: sortBy,
                                    filters: filters,
                                    filterCondition: filterCondition,
                                    groupBy: groupBy,
                                })
                            }
                        >
                            Apply Filters and Sort
                        </Button>
                    </Col>
                    <Col span={12} className="text-right">
                        <Space>
                            <AllStatsSavedFilters
                                savedFilters={savedFilters}
                                setFilters={setFilters}
                                applyButtonRef={applyButtonRef}
                            />
                            {permissions.hasPermission(
                                Permission.PUSH_NOTIFICATIONS_ALL,
                            ) && (
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                key: 'notification-form',
                                                label: (
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        className="bg-white flex space-y-4 flex-col p-10 rounded shadow-md min-w-[500px]"
                                                    >
                                                        <Input
                                                            className="w-full"
                                                            placeholder="Notification Title"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }}
                                                            onChange={(e) => {
                                                                setNotifTitle(
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                        />
                                                        <Input.TextArea
                                                            className="w-full"
                                                            placeholder="Notification Message"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }}
                                                            onChange={(e) => {
                                                                setNotifMessage(
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                            rows={5}
                                                        />
                                                        <Input
                                                            className="w-full"
                                                            placeholder="Deeplink (optional)"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }}
                                                            onChange={(e) => {
                                                                setNotifDeeplink(
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                scheduleModal.open();
                                                            }}
                                                        >
                                                            Send Notification
                                                        </Button>
                                                    </div>
                                                ),
                                            },
                                        ],
                                    }}
                                    trigger={['click']}
                                >
                                    <Button icon={<PlusOutlined />}>
                                        Schedule Notification
                                    </Button>
                                </Dropdown>
                            )}
                            <Button type="link" icon={<DownloadOutlined />}>
                                <CSVLink data={getExportableData(tableData)}>
                                    Export
                                </CSVLink>
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </div>
            <Modal
                title={`This message will be broadcasted to ${tableData.length} users. Are you sure?`}
                open={scheduleModal.isOpen}
                onOk={async () => {
                    const filteredAddresses = tableData.map((user) => user.key);
                    await scheduleNotificationMutation.mutateAsync({
                        message: trimmedNotifMessage,
                        title: trimmedNotifTitle,
                        addresses: filteredAddresses,
                        deeplink:
                            notifDeeplink.trim() === ''
                                ? undefined
                                : notifDeeplink,
                    });
                    scheduleModal.close();
                    setNotifTitle('');
                    setNotifMessage('');
                    setNotifDeeplink('');
                }}
                okButtonProps={{
                    loading: scheduleNotificationMutation.isPending,
                }}
                onCancel={scheduleModal.close}
            >
                <div className="bg-blue-100 w-[100%] space-y-2 flex flex-col rounded-lg p-6 mb-4">
                    <span>Title:</span>
                    <Typography.Title level={4}>{notifTitle}</Typography.Title>
                    <span>Message:</span>
                    <Typography.Title level={4}>
                        {notifMessage}
                    </Typography.Title>
                    <span>Deeplink:</span>
                    <Typography.Title level={4}>
                        {notifDeeplink}
                    </Typography.Title>

                    <span className="text-orange-500 mt-4">
                        Please make sure that the entered deeplink does not
                        crash the app
                    </span>
                </div>
            </Modal>

            <Modal
                title="Save Filter"
                open={saveFiltersModal.isOpen}
                onCancel={saveFiltersModal.close}
                okButtonProps={{
                    disabled: filterName.trim() === '',
                }}
                onOk={() => {
                    const savedFilters = Storage.getJson<
                        Array<AllStatsSavedFilter>
                    >(StorageKeys.ALL_STATS_FILTERS);

                    const newSavedFilters = [
                        ...(savedFilters ?? []),
                        {
                            name: filterName,
                            filters: filters,
                        },
                    ];
                    Storage.setJson(
                        StorageKeys.ALL_STATS_FILTERS,
                        newSavedFilters,
                    );
                    setSavedFilters(newSavedFilters);
                    saveFiltersModal.close();
                    setFilterName('');
                }}
            >
                <Input
                    className="mt-4 mb-4"
                    placeholder="Filter Name"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                />
            </Modal>

            <div className="mb-4">
                <Typography.Text strong className="text-lg">
                    Total Rows: {totals.totalRows}
                </Typography.Text>
            </div>
            <Table
                pagination={{
                    pageSize: PAGE_SIZE,
                    hideOnSinglePage: true,
                }}
                dataSource={tableData}
                expandable={
                    balances && balancesUSD
                        ? {
                              expandedRowRender: (record) => (
                                  <div className="bg-gray-50 p-4 rounded-md">
                                      {Object.entries(
                                          balancesUSD[record.key] ?? {},
                                      ).map(
                                          ([token, balance]) =>
                                              balance > 0.1 && (
                                                  <p
                                                      key={token + record.key}
                                                      className="mb-2"
                                                  >
                                                      <span className="font-semibold">
                                                          {addressToToken[token]
                                                              ?.symbol ?? token}
                                                          :
                                                      </span>{' '}
                                                      {toDollar(balance)} (
                                                      {balances[record.key][
                                                          token
                                                      ].toFixed(
                                                          addressToToken[token]
                                                              ?.precision ?? 2,
                                                      )}
                                                      )
                                                  </p>
                                              ),
                                      )}
                                  </div>
                              ),
                          }
                        : undefined
                }
                columns={columns}
                className="shadow-sm"
            />
        </div>
    );
};
